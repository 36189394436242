.SensorSelector {
    width: 181px;
    height: 182px;
    flex-shrink: 0;
    border-radius: 12.929px;
    background: #2C2C2E;
    display: flex;
    justify-content: center; /* Center horizontally */
}

.SensorSelector-header{
    width: 57px;
    height: 15.464px;
    flex-shrink: 0;
    color: #7D7D7D;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
}

.SensorSelector-option1 {
    width: 324px;
    height: 30px;
    width: 100%;
    flex-shrink: 0;
    background: #3C3C40;
    display: flex;
    justify-content: center; /* Center horizontally */
    position: absolute;
    top: 41px;
}

.SensorSelector-h2 {
    width: 66px;
    height: 14.275px;
    flex-shrink: 0;
    color: #FFF;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
}