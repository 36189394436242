.AgriloRecommendationsBox {
    position: relative;
    width: 875px;
    height: 214px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
}

.AgriloRecommendationsBox-icon {
    position: relative;
    width: 14.053px;
    height: 13.462px;
    flex-shrink: 0;
    left: 20px;
    top: 15px;

}

.AgriloRecommendationsBox-h1 {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    top: -13px;
    left: 40px;
}

.AgriloRecommendationsBox-line1 {
    position: relative;
    border-bottom: solid 2px #F3F3F7;
    top: -10px;
}

.AgriloRecommendationsBox-recommendations {
    display: flex;
    justify-content: center;
}

.recommendationBox {
    width: 816px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FCFCFC;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.status {
    border-radius: 11.148px;
    background: #4C6CFD;
    width: 77.698px;
    height: 22.296px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 140px;
}

.status2 {
    border-radius: 11.148px;
    background: #C83A31;
    width: 77.698px;
    height: 22.296px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 140px;
}

.status-tag {
    color: #FFF;
    font-family: Poppins;
    font-size: 10.529px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.421px;

}

.recommendation-tag {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    right: 125px;
}

.line2 {
    height: 100%;
    border-right: solid 2px #F3F3F7;
    position: relative;
    right: 80px;
}

.title-tag {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    right: 50px;
}

.date-tag {
    color: #B4B4B4;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    right: 30px;
}

.type1 {
    height: 21px;
    width: 66px;
    flex-shrink: 0;
    border-radius: 20.432px;
    border: 1.5px solid #89888D;
    display: flex;
    justify-content: center;
    align-items: center;
}

.type2 {
    height: 21px;
    width: 66px;
    flex-shrink: 0;
    border-radius: 20.432px;
    border: 1.5px solid #89888D;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 20px;
}

.type-tag {
    color: #89888D;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}