.RecommendationSelectionBox {
    width: 594px;
    height: 827px;
    flex-shrink: 0;
    border-radius: 9.562px;
    background: #1E1E20;
    position: absolute;
}


.RecommendationSelectionBox-info-box {
    width: 100%;
    height: 60px;
    max-width: 100%;
    flex-shrink: 0;
    background: #3C3C40;
    display: flex;
    align-items: center;
}

.RecommendationSelectionBox-header-text {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 1.4px;
    margin-top: 20px;
    margin-left: 31px;
    margin-bottom: 18px;
}

.RecommendationSelectionBox-h2 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    width: 29px;
    height: 10px;
    flex-shrink: 0;
    margin-right: 10px
}

.RecommendationSelectionBox-h3 {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-right: 20px;
    margin-top: 12px;
}

.RecommendationSelectionBox-h4 {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
}

.RecommendationSelectionBox-v1 {
    border-left: 2px solid rgba(255, 255, 255, 0.17);
    width: 5px;
    height: 100%;
    /* Or set a specific height */
    left: 451px;
    position: relative;
}

.RecommendationSelectionBox-rectangle {
    width: 88px;
    height: 25px;
    background-color: #2C2C2E;
    border-radius: 12.5px;
    text-align: center;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    left: 477px;
    position: absolute;
}

.RecommendationSelectionBox-h2.test {
    margin-left: 24px;
}

.RecommendationSelectionBox-resaults {
    margin-top: 70px;
    right: 65px;
}

.RecommendationSelectionBox-status-icon {
    width: 23px;
    height: 23px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 6px solid #B3B3B3;
    transform: scaleX(-1);
    position: relative;
    top: 25px;
    left: 28px;
}

.RecommendationSelectionBox-result {
    color: #FFEB03;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.867px;
    position: relative;
    top: -30px;
    right: 455px;
    margin-left: 520px;
}

.RecommendationSelectionBox-result-format {
    color: #FFEB03;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 16.201px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.648px;
    position: relative;
    top: -75px;
    right: 400px;
    margin-left: 540px;

}


.RecommendationSelectionBox-recommendation-box {
    width: 100%;
    height: 120px;
    top: 200px;
    position: absolute;
    max-width: 100%;
    flex-shrink: 0;
    background: #3C3C40;
    display: flex;
    align-items: center;
}

.RecommendationSelectionBox-h5 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.1px;
    position: relative;
    left: 22px;
}

.RecommendationSelectionBox-v2 {
    border-left: 2px solid rgba(255, 255, 255, 0.17);
    width: 5px;
    height: 100%;
    /* Or set a specific height */
    left: 158px;
    position: relative;
}

.RecommendationSelectionBox-status-box {
    width: 147px;
    height: 25px;
    background-color: #2C2C2E;
    border-radius: 12.5px;
    text-align: center;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    position: absolute;
    left: 420px;
    top: 150px;
}

.RecommendationSelectionBox-h6 {
    color: #1f1f20;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
}

.RecommendationSelectionBox-diagnosis {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    width: 391px;
    position: relative;
    left: 50px;
    top: 0px;
}

.RecommendationSelectionBox-recommendations {
    position: relative;
    top: 80px;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 145.455% */
    letter-spacing: 1.1px;
    width: 526px;
}