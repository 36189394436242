.AgriloRecentTestBox {
    width: 25rem;
    height: 10rem;
    border-radius: 7.276px;
    border: 0.2rem solid #F3F3F7;
    background: #FFF;
    position: relative;
    margin: 20px;
    top: -20px;
}

.AgriloRecentTestBox-top-box{
    display: flex;
    height: fit-content;
    align-items: center;
    margin-top: 10px;
    margin-left: 1rem;
}

.AgriloRecentTestBox-h1 {
    position: relative;
    width: 100%;
    max-width: 100%;
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
}

.AgriloRecentTestBox-status {
    width: fit-content;
    padding: 0 1rem 0 1rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    position: relative;
    margin-right: 1.5rem;
}

.AgriloRecentTestBox-h4 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.281px;
}

.AgriloRecentTestBox-type-boxies{
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    margin-left: 1rem;
}

.AgriloRecentTestBox-type1 {
    height: 1.5rem;
    width: fit-content;
    padding: 0 0.5rem 0 0.5rem;
    border-radius: 20.432px;
    border: 1.5px solid #89888D;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.AgriloRecentTestBox-type2 {
    height: 1.5rem;
    width: fit-content;
    padding: 0 0.5rem 0 0.5rem;
    border-radius: 20.432px;
    border: 1.5px solid #89888D;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 1rem;
}

.AgriloRecentTestBox-h5 {
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.AgriloRecentTestBox-results{
    width: fit-content;
    height: fit-content;
    display: flex;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.AgriloRecentTestBox-h2 {
    width: fit-content;
    height: fit-content;
    flex-shrink: 0;
    color: #4C6CFD;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 1.197px;
    position: relative;
}

.AgriloRecentTestBox-h3 {
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.544px;
    position: relative;
    margin-left: 0.5rem;
}