.ProfHistoryBox {
    width: 875px;
    height: 350px;
    min-height: fit-content;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    position: relative;
    margin-left: 15px;
    margin-top: 50px;
}

.ProfHistoryBox-h1 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.2px;
    position: relative;
    left: 20px;
    top: 5px;
}

.ProfHistoryBox-line1 {
    border-bottom: 2px solid #F3F3F7;
    width: 100%;
    height: 2px;
    /* Or set a specific height */
    position: relative;
}

/* labelbox */
.ProfHistoryBox-labels {
    width: 100%;
    height: 30px;
    position: relative;
}

.ProfHistoryBox-h2 {
    color: #B3B3B3;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: absolute;
}

.ProfHistoryBox-h2.Status {
    left: 43px;
}

.ProfHistoryBox-h2.Analyte {
    left: 170px;
}

.ProfHistoryBox-h2.Test {
    left: 310px;
}

.ProfHistoryBox-h2.Date {
    left: 470px;
}

.ProfHistoryBox-h2.Time {
    left: 620px;
}

.ProfHistoryBox-icon {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 3px solid #B3B3B3;
    flex-shrink: 0;
    transform: scaleX(-1);
    position: absolute;
    top: 8px;
}

.ProfHistoryBox-icon.Status {
    left: 22px;
}

.ProfHistoryBox-icon.Analyte {
    left: 150px;
}

.ProfHistoryBox-icon.Test {
    left: 290px;
}

.ProfHistoryBox-icon.Date {
    left: 440px;
}

.ProfHistoryBox-icon.Time {
    left: 600px;
}

/* option box */

.ProfHistoryBox-option-box {
    width: 100%;
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
}

.ProfHistoryBox-filter-btn {
    width: 80px;
    height: 32px;
    border-radius: 23.263px;
    border: none;
    cursor: pointer;
    background-color: #3C3C40;
    position: relative;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;

    display: flex;
    justify-content: center;
    align-items: center;
    left: 15px;
}

.ProfHistoryBox-report-btn {
    width: 147px;
    height: 32px;
    border-radius: 23.263px;
    border: none;
    cursor: pointer;
    background-color: #3C3C40;
    position: relative;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    left: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ProfHistoryBox-filter-icon {
    position: relative;
    width: 16px;
    height: 15.723px;
    right: 0px;
    margin-right: 5px;
}

/* filter box */

.ProfHistoryBox-filter-box {
    position: relative;
    width: 100%;
    height: 180px;
    border-top: 3px solid #F3F3F7;
    border-bottom: 3px solid #F3F3F7;
    
}

.ProfHistoryBox-filter-daytext {
    width: 200px;
    height: 30px;
    font-size: 18px;
    background-color: #3C3C40;
    color: #B3B3B3;
    border-radius: 10px;
    border: none;
    position: relative;
    top: -10px;
    left: -150px;
}

.ProfHistoryBox-filter-daypicker {
    transform: scale(0.9); /* Scale down to 90% of original size */
    position: relative;
    top: -20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    width: 400px;
    height: 380px;
    background: #3C3C40;
    left: 20px;
    z-index: 100000;
}

.ProfHistoryBox-h5 {
    color: #B3B3B3;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: absolute;
}

.ProfHistoryBox-h5.date {
    position: relative;
    right: 780px;
}

.ProfHistoryBox-h5.severity {
    top: 0px;
    right: 450px;
}

.ProfHistoryBox-severity-selector {
    width: 200px;
    height: 30px;
    font-size: 18px;
    background-color: #3C3C40;
    color: #B3B3B3;
    border-radius: 10px;
    border: none;
    position: relative;
    top: -10px;
    left: 300px;
}

.ProfHistoryBox-search-btn {
    width: 75px;
    height: 32px;
    border-radius: 23.263px;
    border: none;
    cursor: pointer;
    background-color: #3C3C40;
    position: relative;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    top: -12px;
    left: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ProfHistoryBox-reset-btn {
    width: 75px;
    height: 32px;
    border-radius: 23.263px;
    border: none;
    cursor: pointer;
    background-color: #3C3C40;
    position: relative;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    top: 20px;
    left: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* line */

.ProfHistoryBox-line2 {
    border-bottom: 2px solid #F3F3F7;
    width: 100%;
    height: 1px;
    /* Or set a specific height */
    position: relative;
}

.ProfHistoryBox-ul {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
    left: 0px;
    height: 100px;
    overflow: auto;
}


.ProfHistoryBox-test-item {
    position: relative;
    display: flex;
    padding: 8px 16px;
}

.ProfHistoryBox-test-item:hover {
    background: #3C3C40;
    cursor: pointer
}

.ProfHistoryBox-test-item.active {
    background-color: #3C3C40;
}

.ProfHistoryBox-test-info {
    padding-right: 80px;
    /* Spacing between info items */
}

/* status box */

.ProfHistoryBox-status-box {
    width: 100px;
    height: 24.095px;
    flex-shrink: 0;
    background-color: #2C2C2E;
    border-radius: 12px;
    position: relative;
    display: flex;
}

.ProfHistoryBox-h3 {
    color: #34A851;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: relative;
    left: 10px;
    top: -2px;
    margin-right: 10px;
}

.ProfHistoryBox-h4 {
    color: #34A851;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 7.477px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.299px;
    position: relative;
    top: 2px;
    margin-left: 2px;
}

.ProfHistoryBox-line3 {
    border-right: 1px solid #141414;
    width: 2px;
    height: 20px;
    /* Or set a specific height */
    position: relative;
    top: 2px;
    left: 5px;
}

.ProfHistoryBox-status-icon {
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    border: 3px solid #B3B3B3;
    flex-shrink: 0;
    transform: scaleX(-1);
    position: relative;
    top: 6px;
    left: 4px;
}

.ProfHistoryBox-type-box {
    width: 100px;
    height: 24.095px;
    flex-shrink: 0;
    background-color: #2C2C2E;
    border-radius: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */

    margin-left: 30px;
    color: #B3B3B3;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
}

.ProfHistoryBox-item-container{
    height: 350px;
}

.ProfHistoryBox-item-name {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: relative;
    width: 200px;
    top: 4px;
    left: 48px;
}

.ProfHistoryBox-item-date {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: relative;
    top: 4px;
    right: 0px;
}

.ProfHistoryBox-item-time{
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: relative;
    top: 4px;
    left: 55px;
}