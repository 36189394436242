.ComposedBox {
    width: 641px;
    height: 625px;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    position: relative;
    left: 20px;
    top: 100px;
}

.ComposedBox-header-text {
    color: #828282;
    font-family: "Roboto Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.2px;
    position: relative;
    left: 20px;
    top: 5px;
}

.ComposedBox-line1 {
    position: relative;
    border-bottom: solid 2px #F3F3F7;
    top: 14px;
    width: 100%;
}

.ComposedBox-to-box {
    width: 100%;
    height: 46px;
    top: 20px;
    position: relative;
    background: #F3F5F6;
    display: flex;
    align-items: center;
}

.ComposedBox-to-text {
    color: #7D7D7D;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    position: relative;
    margin-left: 50px;
}


.ComposedBox-editBox {
    position: relative;
    top: 50px;
    max-width: 100%;
    
}

.ComposedBox-add-btn {
    position: relative;
    margin-left: 20px;
    border-radius: 15px;
    font-size: 13px;
    width: 50px;
    height: 30px;
    color: white;
    background-color: #848491;
    border: none;
    box-shadow: none;
    cursor: pointer;
}

.ComposedBox-add-box {
    position: absolute;
    top: 0px;
    left: 200px;
    min-width: 200px;
    width: fit-content;
    background-color: #696972;
    height: fit-content;
    min-height: 30px;
    padding-bottom: 10px;
    z-index: 10; 
}

.ComposedBox-picked-btn {
    position: relative;
    margin-left: 20px;
    border-radius: 15px;
    font-size: 13px;
    color: white;
    background-color: #8a8a96;
    box-shadow: none;
    cursor: pointer;
}

.ComposedBox-picked-btn:hover {
    position: relative;
    margin-left: 20px;
    border-radius: 15px;
    font-size: 13px;
    color: white;
    background-color: red;
    box-shadow: none;
    cursor: pointer;
}


.ComposedBox-ul {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
    left: 0px;
    height: 200px;
    overflow: auto;
}

.ComposedBox-patient-item {
    position: relative;
    padding: 8px 16px;
    height: 30px;
}

.ComposedBox-patient-item:hover {
    background: #3C3C40;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.17);
}

.ComposedBox-test-item.active {
    background-color: #3C3C40;
}

.ComposedBox-patient-sender {
    color: #FFF;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.ComposedBox-send-button {
    position: relative;
    margin-top: 70px;
    left: 10px;
    height: 35px;
    border-radius: 25px;
    width: 80px;
    font-size: 20px;
    background-color: #867a7ac4;
    cursor: pointer;
    color: white;
    box-shadow: none;
    border: none;
}

.ComposedBox-send-button:hover {
    background-color: #a5abad;
    color: black;
}

.ComposedBox-subject-box {
    width: 100%;
    height: 46px;
    top: 20px;
    position: relative;
    background: #F3F5F6;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.ComposedBox-subject-text {
    color: #7D7D7D;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    position: relative;
    margin-left: 50px;
}

.ComposedBox-subject-input{
    position: relative;
    background: #F3F5F6;
    color: black;
    border-color: grey;
    margin-left: 20px;
    border: none;
    border-bottom: 2px solid #ccc;
    box-shadow: none;
}

.ComposedBox-line1 {
    border-right: 2px solid #a7a7a8;
    width: 2px;
    height: 45px;
    margin-left: 10px;
    /* Or set a specific height */
    position: relative;
    top: 0px;
    left: 5px;
}