.AgriloProfUserPage {
    width: 100rem;
    height: auto;
    padding-bottom: 200px;
    border-radius: 10px;
    background: #FCFCFC;
    position: relative;
    left: 250px;
    top: 75px;
}

.AgriloProfUserPage-h1 {
    color: #373737;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    left: 72px;
    top: 14px;
}

.AgriloProfUserPage-line1 {
    position: relative;
    border-bottom: solid 2px #F3F3F7;
    top: 14px;
    width: 100%;
}

.PatientsBox {
    top: 65px;
    left: 69px;
}

.AgriloProfUserPage-back-btn {
    position: relative;
    top: 25px;
    left: 10px;
}

.AgriloProfUserAverageBox {
    top: 110px;
}

.AgriloProfUsersOverviewBox {
    top: 65px;
}