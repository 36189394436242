.MessageBox {
    width: 641px;
    height: 277px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    position: relative;
    top: 50px;
    left: 20px;
}

.MessageBox-h1 {
    font-family: "Roboto Mono";
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    left: 20px;
    top: 5px;
}

.MessageBox-line1 {
    position: relative;
    border-bottom: solid 2px #F3F3F7;
    top: 14px;
    width: 100%;
}


.MessageBox-ul {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
    left: 0px;
    height: 200px;
    overflow: auto;
    top: 20px;
}


.MessageBox-email-item {
    position: relative;
    padding: 8px 16px;
    height: 50px;
}

.MessageBox-email-item:hover {
    background: #F3F5F6;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.17);
}

.MessageBox-test-item.active {
    background-color: #3C3C40;
}


.MessageBox-email-sender {
    color: #000;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.MessageBox-item-date {
    color: #000;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 350px;
}

.MessageBox-email-text {
    position: absolute;
    color: #5c5959;
    top: 40px;
    left: 20px;
    text-align: left;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;

}

.MessageBox-options {
    width: 164px;
    height: 31px;
    border-radius: 20px;
    position: absolute;
    background: #7b7b83;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 400px;
    top: 10px;
}

.MessageBox-sent-btn {
    width: 80px;
    height: 25px;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    text-decoration: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
}

.MessageBox-inbox-btn {
    width: 80px;
    height: 25px;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    text-decoration: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
}