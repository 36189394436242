.DisplayMessageBox {
    width: 520px;
    height: 100%;
    position: absolute;
    right: 0px;
    border-left: solid 2px #F3F3F7;
    top: 0px;
    min-height: fit-content;
}

.DisplayMessageBox-label {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    left: 30px;
    position: relative;
}


.DisplayMessageBox-spotlight {
    border-top: solid 2px #F3F3F7;
    border-bottom: solid 2px #F3F3F7;
    height: 46px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 80px;
}

.DisplayMessageBox-message-box {
    border-top: solid 2px #bcbcc4;
    height: 46px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 20px;
}

.DisplayMessageBox-h1 {
    color: #000;
    text-align: left;
    font-family: Poppins;
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    margin-left: 20px;
}