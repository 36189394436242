.ComingSoon {
    width: 1512px;
    height: 1000px;
    max-width: 100%;
    min-width: 100%;
    min-height: 100vh;
    background: linear-gradient(180deg, #4E5E80 0%, #10131A 46.36%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Arial, sans-serif;
    position: relative;
}

.ComingSoon-box {
    position: absolute;
    width: 1512px;
    height: 550px;
    max-width: 60%;
    top: 125px;
    left: 20%;
    flex-shrink: 0;
    border-radius: 11.529px;
    border: 0.715px solid rgba(255, 255, 255, 0.40);
    background: rgba(250, 250, 250, 0.08);
    backdrop-filter: blur(1.537209391593933px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.ComingSoon-header{
    font-size:200%;
}
