.AgriloTaskBox {
    position: relative;
    width: 875px;
    height: auto;
    min-height: con;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    block-size: fit-content;
    padding-bottom: 30px;
}

.AgriloTaskBox-icon {
    position: relative;
    width: 14.053px;
    height: 13.462px;
    flex-shrink: 0;
    left: 20px;
    top: 20px;

}


.AgriloTaskBox-h1 {
    color: #373737;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    position: relative;
    top: -10px;
    left: 40px;
}

.AgriloTaskBox-line1 {
    position: relative;
    border-bottom: solid 2px #F3F3F7;
    top: 0px;
}

.AgriloTaskBox-taskboxes {
    width: 800px;
    height: 25px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    top: 10px;
    left: 36px;
}

.AgriloTaskBox-date {
    position: relative;
    color: #B4B4B4;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.AgriloTaskBox-name {
    position: relative;
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    left: 12px;
}

.AgriloTaskBox-radio {
    position: relative;
    height: 15px;
    width: 15px;

}

.AgriloTaskBox-dots {
    width: 16px;
    height: 4px;
    flex-shrink: 0;
    position: absolute;
    right: 30px;
}

.AgriloTaskBox-ul {
    margin: 0;
    padding: 0;
    position: relative;
}