.AgriloTestBox-header {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.AgriloTestsBox {
    position: relative;
    width: 60rem;
    height: fit-content;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    padding-bottom: 1rem;
}

.AgriloTestsBox-icon {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
}

.AgriloTestsBox-h1 {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    margin-left: 0.5rem;
}

.AgriloTestsBox-filter-button {
    position: relative;
    width: 5rem;
    height: 2rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13.231px;
    border: 1px solid #919191;
    background: #FFF;
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-right: 0rem;
    cursor: pointer;
}

.AgriloTestsBox-box-filter-button {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AgriloTestsBox-line1 {
    position: relative;
    border-bottom: solid 2px #F3F3F7;
}

.AgriloTestsBox-h2 {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
}

.AgriloTestsBox-h2.recent {
    position: relative;
    margin-top: 1rem;
    margin-left: 2rem;
}

.AgriloTestsBox-h2.all {
    position: relative;
    margin-right: 50rem;
    font-size: 1.5rem;
}

.AgriloTestsBox-line2 {
    width: 100%;
    position: relative;
    border-bottom: solid 3px #F3F3F7;
}

.AgriloTestsBox-recent-test {
    display: flex;
    justify-content: center;
    align-items: center;
}



.AgriloTestsBox-ul {
    margin: 0;
    padding: 0;
    position: relative;
    margin-top: 10px;
    left: 0px;
    min-height: 20rem;
    overflow: auto;
}


.test-item {
    position: relative;
    display: flex;
    padding: 8px 16px;
    width: 100%;
}

.test-item:hover {
    background: #3C3C40;
    cursor: pointer
}

.test-item.active {
    background-color: #3C3C40;
}


/* status box */

/* .AgriloTestsBox-status-box {
    width: 88px;
    height: 24.095px;
    flex-shrink: 0;
    background-color: #2C2C2E;
    border-radius: 12px;
    position: relative;
    display: flex;
} */

/* .AgriloTestsBox-h3 {
    color: #34A851;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 100rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: relative;
    left: 10px;
    top: -2px;
    margin-right: 10px;
} */
/* 
.AgriloTestsBox-h4 {
    color: #34A851;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 7.477px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.299px;
    position: relative;
    top: 2px;
    margin-left: 2px;
} */


.AgriloTestsBox-test-item {
    position: relative;
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}

.AgriloTestsBox-test-item:hover {
    background: #F3F3F7;
    cursor: pointer
}

.AgriloTestsBox-test-item.active {
    background-color: #F3F3F7;
}


.AgriloTestsBox-item-name {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    left: 3rem;
}

.AgriloTestsBox-item-date {
    color: #B4B4B4;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    left: 13rem;
}

.AgriloTestsBox-plant-type {
    height: fit-content;
    width: fit-content;
    padding: 0 0.5rem 0 0.5rem;
    flex-shrink: 0;
    display: flex;
    border-radius: 20.432px;
    border: 1.5px solid #89888D;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    left: 25rem;
}

.AgriloTestsBox-chemical-type {
    height: fit-content;
    width: fit-content;
    padding: 0 0.5rem 0 0.5rem;
    flex-shrink: 0;
    display: flex;
    border-radius: 20.432px;
    border: 1.5px solid #89888D;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    left: 31rem;
}

.AgriloTestsBox-unit-amount-box {
    height: 2rem;
    width: fit-content;
    display: flex;
    align-items: center;
    position: absolute;
    left: 40rem;
}

.AgriloTestsBox-unit-amount {
    height: fit-content;
    font-family: "Roboto Mono";
    font-size: 2rem;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.901px;
    text-align: right;
    position: relative;
}

.AgriloTestsBox-units {
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.41px;
    position: relative;
    margin-left: 0.5rem;
}


/* filterbox */

.AgriloTestsBox-filter-box {
    position: relative;
    width: 100%;
    height: fit-content;
    padding-bottom: 2rem;
    border-bottom: solid 3px #F3F3F7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
}

.AgriloTestsBox-filter-box-to {
    width: 10rem;
    height: 2rem;
    border-radius: 10px;
    border: 3px solid #dadadd;
    margin-right: 1rem;
}

.AgriloTestsBox-filter-box-select {
    margin-top: 1rem;
}

.AgriloTestsBox-filter-label {
    margin-right: 2rem;
}

.AgriloTestsBox-filter-box-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.AgriloTestsBox-filter-box-reset {
    margin-right: 1rem;
    border-radius: 10px;
    border: 3px solid #dadadd;
    background: #FFF;
    cursor: pointer;
    color: #949494;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}


.AgriloTestsBox-filter-box-filter {
    margin-right: 1rem;
    border-radius: 10px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    background-color: #4C6CFD;
}

.AgriloTestsBox-filter-select {
    font-size: 1rem;
    border-radius: 10px;
    width: 10rem;
    height: 2rem;
    border: 3px solid #dadadd;
}

.AgriloTestsBox-filter-daypicker {
    position: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    width: 400px;
    height: 380px;
    background: #3C3C40;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100000;
}