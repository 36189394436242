.ZenroProfNavagatorBar {
    width: 146px;
    height: 320px;
    top: 150px;
    left: 28px;
    position: fixed;
}

.ZenroProfNavagatorBar-li {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ZenroProfNavagatorBar-summary-btn {
    border: none;
    position: absolute;
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    background-color: #ffffff00;
    cursor: pointer;
}

.ZenroProfNavagatorBar-summary-menu {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    top: 10px;
    left: -30px;
    list-style: none;
}



.ZenroProfNavagatorBar-CalendarIcon {
    position: relative;
    right: 3px;
    width: 15px;
    height: 15px;
}

.TasksIcon {
    position: relative;
    right: 4px;
    width: 16px;
    height: 16px;
}

.PinnedIcon {
    position: relative;
    right: 1px;
    margin-right: 6px;
    width: 10px;
    height: 15px;
}


.ZenroProfNavagatorBar-history-btn {
    border: none;
    position: absolute;
    top: 90px;
    background-color: #ffffff00;
    cursor: pointer;
}

.ZenroProfNavagatorBar-history-menu {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    top: 10px;
    left: -30px;
    list-style: none;
}

.ZenroProfNavagatorBar-OverviewIcon {
    position: relative;
    margin-right: 6px;
    width: 14px;
    height: 13.5px;
}

.ZenroProfNavagatorBar-CareSummaryIcon {
    position: relative;
    margin-right: 6px;
    width: 14px;
    height: 13.5px;
}

.ZenroProfNavagatorBar-TestsIcon {
    position: relative;
    margin-right: 6px;
    width: 14.3px;
    height: 14px;
}

.ZenroProfNavagatorBar-RecommendationsIcon {
    position: relative;
    margin-right: 6px;
    width: 15.9px;
    height: 15.6px;
}



.ZenroProfNavagatorBar-h1 {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    top: 170px;
    left: 10px;
}

.ZenroProfNavagatorBar-message-btn {
    border: none;
    position: absolute;
    top: 100px;
    background-color: #ffffff00;
    cursor: pointer;
}

.ZenroProfNavagatorBar-message-menu {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: absolute;
    top: 110px;
    left: -30px;
    list-style: none;
}

.ZenroProfNavagatorBar-logout-btn{
    border: none;
    position: absolute;
    top: 200px;
    background-color: #ffffff00;
    cursor: pointer;
}