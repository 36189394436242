/* src/pages/LoginPage.css */
.loginPage {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background: #100F13;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
  min-height: 100vh;
}

.left-image {
  position: absolute;
  top: 2.5%;
  left: 0px;
  width: 1512px;
  max-width: 20%;
}

.line {
  position: 'absolute';
  top: 0;
  bottom: 0;
  left: '50%';
  width: '100px';
  background: 'white';
}

.right-image-login {
  width: 20%;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.bottom-right-image {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 20%;

}

.LoginPage-username-input {
  position: relative;
  border-radius: 15px;
  border: 1px solid #9CA5B6;
  background: rgba(231, 231, 231, 0.19);
  font-size: large;
  color: white;
  padding: 10px 16px 10px 30px;
  justify-content: 'flex-end';
  align-items: 'center';
  height: 5%;
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8%;
}

.LoginPage-password-input {
  position: relative;
  border-radius: 15px;
  border: 1px solid #9CA5B6;
  background: rgba(231, 231, 231, 0.19);
  font-size: large;
  color: white;
  display: 'inline-flex';
  padding: 10px 16px 10px 30px;
  justify-content: 'flex-end';
  align-items: 'center';
  height: 5%;
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8%;
}

.welcome-text-login {
  color: #FFF;
  font-family: "Century Gothic";
  font-size: 25.496px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.35px;
  position: relative;
  text-align: center;
}

.signin-button {
  background: rgba(255, 255, 255, 0.94);
  color: #4A5979;
  border-radius: 10px;
  border: 1px solid #9CA5B6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 10%;
  flex-shrink: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.459px;
  cursor: pointer;
  gap: 11px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8%;
}

.LoginPage-form {
  width: 23%;
  height: 70%;
  top: 10%;
  position: relative;
  left: 24%;
  min-width: fit-content;
  min-height: fit-content;
}

.signin-forgot {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #71757A;
  font-family: "Century Gothic";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.56px;
  text-decoration-line: underline;
  margin-top: 5%;
  width: fit-content;
}

.signin-agree {
    color: white;
    font-size: 1rem;
    position: relative;
    text-align: center;
    line-height: 2;
}

.signin-privacy {
  color: white;
  font-size: 1rem;
  text-decoration-line: underline;
}

@media (max-width: 900px){
  .left-image{
    display: none;
  }
  .loginPage-VerticalLine{
    display: none;
  }
  .LoginPage-form{
    left: 0%;
  }
}