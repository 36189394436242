.ZenroProductPage {
    height: auto;
    min-height: 100vh;
    background: linear-gradient(180deg, #4E5E80 0%, #10131A 46.36%);
    color: white;
    font-family: Arial, sans-serif;
}

/* Main Container */
.ZenroProductPage-Container {
    font-family: 'Arial', sans-serif;
    padding-top: 15rem;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ZenroPP-titleDiv{
    margin-bottom: 15rem;
    align-self: start;
    padding-left: 15%;
}

.ZenroPP-Title {
    color: #97ABD8;
    font-family: Poppins;
    font-size: 80px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.ZenroPP-TitleText {
    color: white;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px 0px 30px 0px;
}

.ZenroPP-Main-Content{
    align-content: center;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    max-width: 90%;
}

.ZenroPP-ImgBlock, .ZenroPP-50-50-first {
    margin: 0;
    padding: 0;
}

    .ZenroPP-ImgBlock img {
        display: block;
        margin: 0;
    }

.ZenroPP-50-50-first {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 0%;
    width: 100%;
    height: 500px;
}

.ZenroPP-ImgBlock {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 200px;
    align-self: flex-start;
    flex-shrink: 2;
}

.ZenroPP-ImgBlock-Img1 {
    width: inherit;
    border-radius: 10px;
    position: inherit;
    z-index: 2;
    display: block;
}

.ZenroPP-ImgBlock-Img2 {
    width: 100%;
    border-radius: 10px;
    position: absolute;
    top: 30px;
    left: 140px;
    z-index: 1;
}

.ZenroPP-ImgBlock-Img3 {
    width: 100%;
    border-radius: 10px;
    position: absolute;
    top: 30px;
    left: -140px;
    z-index: 1;
}

.ZenroPP-TextBlock {
    display: inline-block; /* Aligns text beside the image block */
    width: 60%;
    max-width: 40%; /* Optional: Limit the width of the text block */
    align-self: center;
    color: #97ABD8;
}

.ZenroPP-TextBlock-Title {
    color: #7EA6FF;
    padding-bottom: 40px;
    font-size: 30px;
}

h2, p {
    margin: 0 0 10px; /* Add some spacing between the header and paragraph */
    line-height: 1.4; /* Reset margins for better alignment */
}

.ZenroPP-UL {
    text-align: left; /* Ensures the text is left-aligned */
    list-style-position: inside;
}

.ZenroPP-LI {
    margin-bottom: 20px;
}

.ZenroPP-VideoBlock video{
    width: 70%;
    height: auto;
}

.AgriloPP-VideoBlock video{
    width: 60%;
    height: auto;
}

@media (max-width: 900px){
    .ZenroPP-ImgBlock{
        scale: .7;
    }
    .ZenroPP-UL{
        display: none;
    }
}

@media (max-width: 650px){
    .ZenroPP-Title{
        font-size: 3rem;
    }
    .ZenroPP-TitleText{
        font-size: 1.5rem;
    }
    .ZenroPP-ImgBlock{
        scale: .5;
    }
    .ZenroPP-TextBlock-Title{
        font-size: 1.5rem;
    }
}

@media (max-width: 525px){
    .ZenroPP-Title{
        font-size: 3rem;
    }
    .ZenroPP-TitleText{
        font-size: 1.25rem;
    }
    .ZenroPP-ImgBlock{
        scale: .4;
    }
    .ZenroPP-TextBlock-Title{
        font-size: 1.4rem;
    }
    .ZenroPP-TextBlock p{
        font-size: .8rem;
    }
}