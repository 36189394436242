.HistoryPage {
  width: 1412px;
  height: 850px;
  min-height: auto;
  background: #100F13;
  display: flex;
  align-items: center; /* Center vertically */
  position: relative;
  left: 130px;
  top: 25px;
}

.SelectHistoryBox {
  top: 15px;
  left: 750px;
}

.HistoryBox {
  left: 100px;
}