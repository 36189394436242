.Agrilo {
    height: fit-content; /* Use vh (viewport height) to always be full screen */
    width: 100vw;
    height: 100%;
    min-width: 1550px;
    min-height: fit-content;
    background: #F3F2F8;
    padding-bottom: 100px;
    top: 0px;
    position: absolute;
}


.AgriloNavagatorBar {
    top: 60px;
    left: 25px;
}

.Agrilo-icon {
    display: flex;
    align-content: center;
    align-items: center;
    position: fixed;
    margin-top: 1rem;
    margin-left: 1rem;
    cursor: pointer;
}

.Agrilo-h1 {
    color: #373737;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
}

.agriloIconTop {
    position: relative;
    width: 3rem;
    height: 2rem;
}