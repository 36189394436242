.OverView {
  width: 1412px;
  height: 700px;
  min-height:auto;
  background: #100F13;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: relative;
  left: 130px;
  top: 25px;
}


.AverageTestBox1 {
  position: absolute;
  top: 37px;
  left: 744px;
  transform: scale(1.03);  /*Adjust the scale value as needed */
  transform-origin: top left; /*Adjust the origin point if necessary*/
}

.AverageTestBox2 {
  top: 37px;
  left: 1050px;
  transform: scale(1.03);  /*Adjust the scale value as needed */
  transform-origin: top left; /*Adjust the origin point if necessary*/
}

.AverageTestBox1, .AverageTestBox2, .RecentTestBox1, .RecentTestBox2{
  position: absolute; /* Position the boxes absolutely within the container */
}

.SetUpBox {
  position: absolute;
  top: 37px;
  left: 121px;
}

.RecentTestBox1 {
  position: absolute;
  top: 320px;
  left: 121px;
}

.RecentTestBox2 {
  position: absolute;
  top: 320px;
  left: 744px;
}