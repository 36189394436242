.AgriloProfEventBar {
    width: 20rem;
    height: 100%;
    position: absolute;
    right: 0px;
    border-left: solid 2px #F3F3F7;
    top: 0px;
}

.AgriloProfEventBar-event-start {
    border-top: solid 2px #F3F3F7;
    border-bottom: solid 2px #F3F3F7;
    height: 46px;
    display: flex;
    align-items: center;
    position: relative;
    top: 100px;
}

.AgriloProfEventBar-event-text {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    left: 1rem;
    position: relative;
}

.AgriloProfEventBar-ul {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    top: 110px;
}


.AgriloProfEventBar-test-item {
    position: relative;
    padding-top: 10px;
}

.AgriloProfEventBar-eventbox {
    position: relative;
    height: 5rem;
    width: 100%;
    border-bottom: solid 2px #F3F3F7;
}

.AgriloProfEventBar-task-color {
    width: 29px;
    height: 8px;
    flex-shrink: 0;
    border-radius: 6.75px;
    position: relative;
    left: 20px;
}

.AgriloProfEventBar-task-name {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    bottom: 1.75rem;
    left: 60px;
}

.AgriloProfEventBar-task-range {
    color: #666668;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    bottom: 30px;
    left: 60px;
}

.AgriloProfEventBar-description {
    position: relative;
    height: fit-content;
    max-height: 150px;
    width: 100%;
    border-bottom: solid 2px #F3F3F7;

}

.AgriloProfEventBar-description-header {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    left: 20px;
}



.AgriloProfEventBar-event-info {
    position: relative;
    height: 5rem;
    width: 100%;
    border-bottom: solid 2px #F3F3F7;
    margin-top: 7rem;
}

.AgriloProfEventBar-event-title {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 1rem;
}

.AgriloProfEventBar-event-date {
    color: #949494;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 1rem;
}

.AgriloProfEventBar-event-frequency {
    color: #949494;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 1rem;
}

.AgriloProfEventBar-event-until {
    color: #949494;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 1rem;
}

.AgriloProfEventBar-event-description.label {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 1rem;
}

.AgriloProfEventBar-event-description {
    color: #949494;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 1rem;
}

.AgriloProfEventBar-event-description-box {
    position: relative;
    height: fit-content;
    padding-bottom: 2rem;
    width: 100%;
    border-bottom: solid 2px #F3F3F7;
}

.AgriloProfEventBar-event-user.label {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 1rem;
}

.AgriloProfEventBar-event-users-box {
    position: relative;
    height: fit-content;
    width: 100%;
    border-bottom: solid 2px #F3F3F7;
    min-height: 5rem;
}

.AgriloProfEventBar-event-user {
    color: #949494;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 1rem;
}