.AgriloCalendar {
  position: relative;
  width: 70rem;
  height: 45rem;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #F3F3F7;
  background: #FFF;

}

.AgriloCalendar-icon {
  position: relative;
  width: 14.053px;
  height: 13.462px;
  flex-shrink: 0;
  left: 20px;
  top: 20px;

}

.AgriloCalendar-h1 {
  color: #373737;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.56px;
  position: relative;
  top: -10px;
  left: 40px;
}

.AgriloCalendar-line1 {
  position: relative;
  border-bottom: solid 2px #F3F3F7;
  top: 0px;
}

/* buttons */

.AgriloCalendar-options {
  border-radius: 20px;
  top: 1rem;
  right: 1rem;
  position: absolute;
  background: #747373;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
}


.AgriloCalendar-tests-btn {
  width: 80px;
  height: 25px;
  color: #FFF;
  font-family: "Roboto Mono";
  font-size: 11.025px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.441px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  text-decoration: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.AgriloCalendar-events-btn {
  width: 80px;
  height: 25px;
  color: #FFF;
  font-family: "Roboto Mono";
  font-size: 11.025px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.441px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  text-decoration: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}


/* calendar */
/* Calendar.css */

.react-calendar {
  position: absolute;
  top: 75px;
  left: 10px;
  width: 80rem;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 2em;
}

.react-calendar--doubleView {
  width: 65rem;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 1em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background-color: #f0f0f0;
}

.react-calendar__tile--active {
  background-color: #e6e6e6;
}

/* Highlighted ranges */
.highlight-lightblue {
  background-color: lightblue;

}

.highlight-orange {
  background-color: orange;

}

.highlight-lightgreen {
  background-color: lightgreen;
}

.highlight-pink {
  background-color: lightcoral;
}