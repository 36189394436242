.AgriloProfUsersOverviewBox {
    position: relative;
    width: 92.5rem;
    height: fit-content;
    padding-bottom: 1rem;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    margin-left: 3rem;
}

.AgriloProfUsersOverviewBox-icon {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    left: 20px;
    top: 20px;

}

.AgriloProfUsersOverviewBox-h1 {
    color: #373737;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    position: relative;
    top: -23px;
    left: 55px;
}

.AgriloProfUsersOverviewBox-line1 {
    position: relative;
    border-bottom: solid 2px #F3F3F7;
    top: 0px;
}

.AgriloProfUsersOverviewBox-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AgriloPlaceholder {
    width: 372px;
    height: 253px;
    padding: 20px;
}

.AgriloProfUsersOverviewBox-popup-menu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    width: 50rem;
    height: fit-content;
    padding-bottom: 2rem;
    border-radius: 5px;
    z-index: 100;
    /* Ensure it's on top */
    border: solid 3px #373737;
}

.AgriloProfUsersOverviewBox-setting-icon {
    position: absolute;
    top: 1rem;
    right: 2rem;
    cursor: pointer;
}

.AgriloProfUsersOverviewBox-close-icon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
}

.AgriloProfUsersOverviewBox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    /* Ensure it's behind the popup */
}

.AgriloProfUsersOverviewBox-option-box {
    position: relative;
    margin-top: 2rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
}

.AgriloProfUsersOverviewBox-option-div {
    position: relative;
    width: 90%;
    height: 6rem;
    /* border: solid 2px black;
    background-color: aqua; */
    align-items: center;
    display: flex;
}

.AgriloProfUsersOverviewBox-option-add {
    position: relative;
    width: 30%;
    right: 13rem;
    height: 4rem;
    /* border: solid 2px black;
    background-color: aqua; */
    align-items: center;
    display: flex;
    cursor: pointer;
}

.AgriloProfUsersOverviewBox-add-text {
    color: #373737;
    font-family: Poppins;
    margin-left: 1rem;
}

.AgriloProfUsersOverviewBox-option-picker-box {
    font-size: 0.5rem;
    font-family: Poppins;
    flex-direction: column;
    align-items: center;
    padding-right: 4rem;
    margin-left: 1rem;
}


.AgriloProfUsersOverviewBox-option-remove-text {
    cursor: pointer;
    font-family: Poppins;
    margin-top: 2rem;
}

.AgriloProfUsersOverviewBox-option-remove-text:hover {
    text-decoration: underline;
}

.AgriloProfUsersOverviewBox-save-button{
    position: relative;
    top: 1rem;
    border-radius: 10px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    background-color: #4C6CFD;
    cursor: pointer;
}