.AboutUs {
    height: auto;
    min-height: 100vh;
    background: linear-gradient(180deg, #4E5E80 0%, #10131A 46.36%);
    color: white;
    font-family: Arial, sans-serif;
}

.AboutUs-Container{
    position: relative;
}

.AboutUs-Title {
    color: #97ABD8;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    margin: 0;
    padding-top: 6rem;
    padding-left: 15vw;
    position: relative;
}

.AboutUs-Span {
    display: flex;
    justify-content: space-between;
    padding: 5vw 10vw;
}

.AboutUs-text {
    color: white;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
    padding: 0 5vw;

}

@media (max-width: 1200px){
    .AboutUs-text {
        font-size: 14px;
    }
}

@media (max-width: 950px){
    .AboutUs-text {
        font-size: 12px;
    }
}

@media (max-width: 750px){
    .AboutUs-Span {
        flex-direction: column;
    }

    .AboutUs-text {
        font-size: 16px;
        padding: 3vw 10vw;
    }
}