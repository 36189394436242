.Contact {
  height: auto;
  min-height: 100vh;
  background: linear-gradient(180deg, #4E5E80 0%, #10131A 46.36%);
  color: white;
  font-family: Arial, sans-serif;
  }

.ContactHeader {
    color: #97ABD8;
    font-family: Poppins;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    top: 5rem;
    padding-left: 15%;
    margin: 0;
}

  .ContactBox-email{
    text-wrap: wrap;
  }

  .Contact-box {
    word-wrap: break-word;
    margin: 8rem 15%; 
    width: 20%;
    min-width: 30%;
    max-width: 20%;
    height: auto;
    min-height: fit-content;
    border-radius: 11.529px;
    border: 0.715px solid rgba(255, 255, 255, 0.40);
    background: rgba(250, 250, 250, 0.08);
    backdrop-filter: blur(1.537209391593933px);
    font-family: 'Poppins', sans-serif;
    padding: 2rem;
  }

  @media (max-width: 750px){
    .Contact-box{
      min-width: 70%;
      margin: 8rem auto;
    }
}