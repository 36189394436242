.RecentAverageBox {
    width: 875px;
    height: 350px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    margin-left: 15px;
    margin-top: 50px;
    position: relative;
}

.RecentAverageBox-header1{
    color: #5A5A5A;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align: left;
    position: absolute;
    left: 56px;
    top: 10px;
}

.RecentAverageBox-header2{
    color: #B4B4B4;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.48px;
    right: 54px;
    top: 10px;
    position: absolute;
}

.RecentAverageBox-boxes{
    width: 100%;
    border-top: 2px solid #F3F3F7;
    position: relative;
    height: 300px;
    margin-top: 50px;
    display: flex;
}

.RecentAverageBox-box1{
    position: relative;
    margin-left: 5px;
    margin-top: 20px;
}

.RecentAverageBox-box2{
    position: relative;
    margin-left: 290px;
    margin-top: 20px;
}

.RecentAverageBox-box3{
    position: relative;
    margin-left: 290px;
    margin-top: 20px;
}

/* .RecentAverageBox-box4{
    transform: scale(0.9);
    position: relative;
    margin-left: 220px;
    margin-top: 20px;
} */