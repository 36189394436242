.AgriloProfUserAverageBox {
    position: relative;
    width: 92.5rem;
    height: fit-content;
    padding-bottom: 2rem;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    margin-left: 3rem;
}

.AgriloProfUserAverageBox-icon {
    position: relative;
    width: 1.25rem;
    height: 1.5rem;
    flex-shrink: 0;
    left: 20px;
    top: 25px;

}

.AgriloProfUserAverageBox-h1 {
    color: #373737;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    position: relative;
    top: -20px;
    left: 45px;
}

.AgriloProfUserAverageBox-line1 {
    position: relative;
    border-bottom: solid 2px #F3F3F7;
}


.AgriloProfUserAverageBox-items {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* overviewbox */

.AgriloProfUserAverageBox-overview {
    margin: 20px;
    width: 12rem;
    height: 12rem;
    flex-shrink: 0;
    border-radius: 10px;
    border: solid 2px #F3F3F7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #FCFCFC;
}

.AgriloProfUserAverageBox-overview-header {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    position: relative;
    margin-top: 1rem;
}

.AgriloProfUserAverageBox-overview-dots {
    position: absolute;
    top: 13px;
    right: 15px;
}

.AgriloProfUserAverageBox-overview-type {
    display: flex;
    width: fit-content;
    height: 1rem;
    padding-left: 14px;
    padding-right: 14px;
    justify-content: center;
    align-items: center;
    gap: 5.153px;
    flex-shrink: 0;
    border-radius: 8.502px;
    background: #4C6CFD;
    color: #FFF;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.291px;
    position: relative;
    margin-top: 0.5rem;

}

.AgriloProfUserAverageBox-overview-line {
    width: 100%;
    border-bottom: solid 5px #F3F3F7;
    position: relative;
    top: 0px;
}

.AgriloProfUserAverageBox-overview-value {
    color: #000;
    font-family: "Roboto Mono";
    font-size: 2rem;
    font-style: normal;
    font-weight: 556;
    line-height: normal;
    letter-spacing: -3px;
    position: relative;
    margin-top: 0px;
}

.AgriloProfUserAverageBox-overview-metric {
    color: #000;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.412px;
    position: relative;
    margin-top: -30px;
}

.AgriloProfUserAverageBox-overview-date {
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.252px;
    position: relative;
    margin-top: -10px;
}

/* chemical overviewBox */

.AgriloProfUserAverageBox-ChemOverviewBox {
    width: 205px;
    height: 149px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FCFCFC;
    position: relative;
}

.AgriloProfUserAverageBox-ChemOverviewBox-header {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    position: relative;
    top: 4px;
    left: 15px;
}

.AgriloProfUserAverageBox-ChemOverviewBox-dots {
    position: absolute;
    top: 13px;
    right: 22px;
}

.AgriloProfUserAverageBox-ChemOverviewBox-line {
    border-bottom: solid 2px #F3F3F7;
    position: relative;
    margin-top: 8px;
}

.AgriloProfUserAverageBox-ChemOverviewBox-type {
    display: flex;
    width: fit-content;
    height: 16px;
    padding-left: 14px;
    padding-right: 14px;
    justify-content: center;
    align-items: center;
    gap: 5.153px;
    flex-shrink: 0;
    border-radius: 8.502px;
    background: #4C6CFD;
    color: #FFF;
    font-family: Poppins;
    font-size: 7.267px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.291px;
    position: absolute;
    top: 10px;
}

.AgriloProfUserAverageBox-ChemOverviewBox-big-value {
    color: #000;
    font-family: "Roboto Mono";
    font-size: 36.544px;
    font-style: normal;
    font-weight: 556;
    line-height: normal;
    letter-spacing: 1.462px;
    position: absolute;
    top: 3px;
}

.AgriloProfUserAverageBox-ChemOverviewBox-metric1 {
    color: #000;
    font-family: Poppins;
    font-size: 10.547px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.422px;
    position: absolute;
    top: 65px;
}

.AgriloProfUserAverageBox-ChemOverviewBox-label {
    color: #4C6CFD;
    font-family: Poppins;
    font-size: 6.455px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.258px;
    position: absolute;
    top: 85px;
}

.AgriloProfUserAverageBox-ChemOverviewBox-value {
    color: #000;
    font-family: "Roboto Mono";
    font-size: 18.492px;
    font-style: normal;
    font-weight: 556;
    line-height: normal;
    letter-spacing: 0.74px;
}

.AgriloProfUserAverageBox-ChemOverviewBox-metric {
    color: #000;
    font-family: Poppins;
    font-size: 6.455px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.258px;
}

.AgriloProfUserAverageBox-ChemOverviewBox-month {
    color: #89888D;
    font-family: Poppins;
    font-size: 6.455px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.258px;
}

/* positioning */

.AgriloProfUserAverageBox-ChemOverviewBox-box {
display: flex;
flex-direction: column;
align-items: center;
position: absolute;
}

.AgriloProfUserAverageBox-ChemOverviewBox-box.one {
    width: 100px;
    height: 75px;
    top: 30px;
    position: absolute;
  }

  /* box two */

  .AgriloProfUserAverageBox-ChemOverviewBox-box.two {
    width: 60px;
    height: 75px;
    position: absolute;
    left: 90px;
    top: 50px;
  }

  .AgriloProfUserAverageBox-ChemOverviewBox-value.two{
    position: absolute;
    top: 20px;
  }

  .AgriloProfUserAverageBox-ChemOverviewBox-metric.two{
    position: absolute;
    top: 50px;
  }

  .AgriloProfUserAverageBox-ChemOverviewBox-month.one{
    position: absolute;
    top: 65px;
  }

  /* box three */

  .AgriloProfUserAverageBox-ChemOverviewBox-box.three {
    width: 60px;
    height: 75px;
    position: absolute;
    left: 120px;
    top: 50px;
  }

  .AgriloProfUserAverageBox-ChemOverviewBox-value.three{
    position: absolute;
    top: 20px;
  }

  .AgriloProfUserAverageBox-ChemOverviewBox-metric.three{
    position: absolute;
    top: 50px;
  }

  .AgriloProfUserAverageBox-ChemOverviewBox-month.two{
    position: absolute;
    top: 65px;
  }

  /* box four */

  .AgriloProfUserAverageBox-ChemOverviewBox-box.four {
    width: 60px;
    height: 75px;
    position: absolute;
    left: 150px;
    top: 50px;
  }

  .AgriloProfUserAverageBox-ChemOverviewBox-value.four{
    position: absolute;
    top: 20px;
  }

  .AgriloProfUserAverageBox-ChemOverviewBox-metric.four{
    position: absolute;
    top: 50px;
  }

  .AgriloProfUserAverageBox-ChemOverviewBox-month.three{
    position: absolute;
    top: 65px;
  }