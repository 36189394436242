.AgriloProfUsersBox {
    position: relative;
    width: 70rem;
    height: fit-content;
    padding-bottom: 2rem;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    margin-left: 5rem;
    margin-top: 5rem;
}

.AgriloProfUsersBox-icon {
    position: relative;
    width: 14.053px;
    height: 20px;
    flex-shrink: 0;
    left: 20px;
    top: 25px;

}

.AgriloProfUsersBox-h1 {
    color: #373737;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    position: relative;
    top: -10px;
    left: 40px;
}

.AgriloProfUsersBox-line1 {
    position: relative;
    border-bottom: solid 3px #F3F3F7;
    top: -1rem;
}

.AgriloProfUsersBox-container {
    margin-left: 2rem;
}

.AgriloProfUsersBox-label {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.AgriloProfUsersBox-input-users {
    width: 55rem;
    height: 3rem;
    border-radius: 10px;
    border: 3px solid #dadadd;
    background: #FFF;
    font-size: 1.5rem;
}

.AgriloProfUsersBox-add {
    width: 6rem;
    height: 4rem;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #F3F3F7;
    margin-left: 1rem;
    font-size: 1.5rem;
    color: #949494;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.AgriloProfUsersBox-reset {
    width: 7rem;
    height: 3rem;
    margin-top: 1rem;
    margin-left: 1rem;
    border-radius: 10px;
    border: 3px solid #dadadd;
    background: #FFF;
    cursor: pointer;
    color: #949494;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.AgriloProfUsersBox-send {
    width: 7rem;
    height: 3rem;
    margin-top: 1rem;
    margin-left: 1rem;
    border-radius: 10px;
    border: 3px solid #4C6CFD;
    background: #4C6CFD;
    color: #FFF;
    cursor: pointer;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}


.AgriloProfUsersBox-ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    background-color: #F3F3F7;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: black;
}

.AgriloProfUsersBox-ul:hover {
    background-color: #949494;
    color: white;
}

.AgriloProfUsersBox-user-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    margin-top: 1rem;
    border-radius: 10px;
    border: 3px solid #F3F3F7;
    background: #FFF;
    padding: 0.5rem;
    cursor: pointer;
}

.AgriloProfUsersBox-user-box:hover {
    background: #F3F3F7;
}

.AgriloProfUsersBox-user-selected {
    color: #949494;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
}

.AgriloProfUsersBox-user-item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}