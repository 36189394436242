.plans-container {
    display: flex; /* Horizontal layout */
    justify-content: center; /* Center the cards */
    align-items: flex-start; /* Align at the top */
    padding: 1.25rem;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.plan-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 1rem;
    padding: 1rem;
    width: 12rem;
    gap: 8rem;
    height: auto;
    text-align: center;
    color: white;
    font-family: Arial, sans-serif;
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, 0.2);
}

.plan-header {
    display: inline-flex; /* Ensure the button behaves like a container for its text */
    align-items: center; /* Vertically align the text */
    justify-content: center; /* Center the text horizontally */
    font-size: .75rem;
    letter-spacing: 1px;
    color: #ccc;
    margin-bottom: 3.125rem;
    background: grey;
    border: none;
    border-radius: 1.25rem;
    padding: .25rem;
}

.plan-price {
    font-size: 3rem; /* Adjust size as needed */
    font-weight: bold;
    color: #B4B4B4; /* Text color */
    text-shadow: .125rem .125rem .25rem rgba(0, 0, 0, 0.5); /* Downward shadow */
}

.price-symbol {
    font-size: 1.5rem;
    vertical-align: top;
}

.price-value {
    font-size: 4rem;
}

.plan-title {
    font-size: 1rem;
    margin: .75rem 0;
}

.plan-description {
    font-size: .75rem;
    color: #aaa;
    margin-bottom: .5rem;
    padding: 0 .75rem;
}

.pricing-signup-button {
    display: inline-flex; /* Ensure the button behaves like a container for its text */
    align-items: center; /* Vertically align the text */
    justify-content: center; /* Center the text horizontally */
    background: linear-gradient(to right, #2C2C2E 20%, #2C2C2E53 50%, #2C2C2E 80%);
    color: white; /* Text color will be black to stand out on the white center */
    border: none;
    height: 2rem;
    width: 70%;
    font-size: .75rem;
    border-radius: 1.25rem;
    cursor: pointer;
    margin-bottom: .75rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.3);
    white-space: nowrap;
    transition: background 0.7s;
    line-height: 1; /* Prevent extra spacing between lines */
}


    .signup-button:hover {
        background: #444;
    }

.switch-plan {
    font-size: 100%;
    color: #B3B3B3;
    cursor: pointer;
}

@media (max-width: 900px){
    .plan-card{
        gap: 1rem;
    }
  }