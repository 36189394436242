.AverageTestBox {
    height: 259px;
    width: 280px;
    flex-shrink: 0;
    border-radius: 9.562px;
    background: #4E5E80;
    position: absolute;
}

.AverageTestBox-line {
    border-bottom: 2px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    height: 2px; /* Or set a specific height */
    top: 41px;
    position: absolute;
}

.AverageTestBox-h1 {
    margin-top: 15px;
    text-align: center;
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
}

.AverageTestBox-h2 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 11.239px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.45px;
    width: 58px;
    height: 13px;
    flex-shrink: 0;
}

.AverageTestBox-analyte-box {
    width: 92px;
    height: 20px;
    background-color: #2C2C2E;
    border-radius: 12.5px;
    text-align: center;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    left: 11px;
    margin-top: 18px;
    position: absolute;
}

.AverageTestBox-h2.analyte {
    margin-bottom: 10px;
}

.AverageTestBox-severity-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 3px solid #f54244;
    flex-shrink: 0;
    transform: scaleX(-1);
}

.AverageTestBox-severity-box {
    width: 30px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 12.216px;
    background: #2C2C2E;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: absolute;
    left: 160px;
    margin-top: 18px;
}

.AverageTestBox-h2.average {
    margin-top: 21px;
    left: 200px;
    position: absolute;
}

.AverageTestBox-h3 {
    width: 80.889px;
    height: 65.425px;
    flex-shrink: 0;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    position: absolute;
    margin-top: 60px;
    left: 15px;
}

.AverageTestBox-h4 {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 16.072px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    margin-top: 115px;
    left: 20px;
}

.AverageTestBox-h5 {
    width: 29.16px;
    height: 23.585px;
    flex-shrink: 0;
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 14.5px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.894px;
    
}

.AverageTestBox-h6 {
    width: 23.585px;
    height: 7.22px;
    flex-shrink: 0;
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 9px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.274px;
}

.AverageTestBox-h5.highest {
    margin-top: 72px;
    left: 160px;
    position: absolute;
}

.AverageTestBox-h6.highest {
    margin-top: 90px;
    left: 165px;
    position: absolute;
}

.AverageTestBox-h5.lowest {
    margin-top: 107px;
    left: 160px;
    position: absolute;
}

.AverageTestBox-h6.lowest {
    margin-top: 124px;
    left: 165px;
    position: absolute;
}

.AverageTestBox-h2.highest {
    margin-top: 75px;
    left: 222px;
    position: absolute;
}

.AverageTestBox-h2.lowest {
    margin-top: 110px;
    left: 225px;
    position: absolute;
}

.AverageTestBox-h2.amount {
    margin-top: 185px;
    position: relative;
    display: flex;
    justify-content: center; /* Center horizontally */
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-size: 10px;
}
