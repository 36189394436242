.Products {
    height: auto;
    min-height: 100vh;
    background: linear-gradient(180deg, #4E5E80 0%, #10131A 46.36%);
    color: white;
    font-family: Arial, sans-serif;
}

.Products-container {
    display: flex; /* Makes the container a flex container */
    justify-content: space-evenly; /* Optional: Adds space between divs */
}

.Products-box {
    font-family: 'Arial', sans-serif;
    border-radius: 11.529px;
    border: 0.715px solid rgba(255, 255, 255, 0.40);
    background: rgba(250, 250, 250, 0.08);
    backdrop-filter: blur(1.537209391593933px);
    padding: 40px;
    width: 25%;
    max-width: 25%;
    height: 500px;
    max-height: 600px;
    margin-top: 100px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end; /* Horizontally centers content */
    text-align: center; /* Centers the text inside the div */
}

.Products-header {
    font-size: 200%;
}

.Products-short-description {
    color: #97ABD8;
    justify-content: space-between;
    line-height: 30px;
}

.Zenro-promo-img{
    height: 300px;
    width:300px;
    margin-bottom: -40px;
    padding-right: 20px;
}

.Button-span {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.Explore-Button {
    background-color: #FFFFFF;
    border-color: transparent;
    color: #4A5979;
    border-radius: 12px;
    padding: 5px 30px;
    cursor: pointer;
}

.Get-started-button {
    background-color: #9CA5B6;
    border-color: transparent;
    color: #F5F6F7;
    border-radius: 12px;
    padding: 5px 15px;
    font-size: 12px;
    cursor: pointer;
}

.Button:hover{
    text-decoration: underline;
}

.sensor-guidelines-container{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    padding-top: 5rem;
}

.sensor-block{
    width: calc(33.33% - 2rem);
    max-width: 20rem;
    max-height: 12rem;
    position: relative;
    flex: 1 1 calc(33.33% - 2rem);
}

.sensor-image{
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.sensor-overlay {
    position: absolute; /* Overlay the image */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    background: rgba(95, 95, 95, 0.4); /* Optional: Add a translucent dark overlay */
    color: white; /* Text color for visibility */
    text-align: center;

  }

.sensor-text{
    font-size: 1.75rem;
    font-weight: bold;
    padding-left: 1rem;
}

.sensor-button{
    padding-right: 1rem;
}

@media (max-width: 1024px) {
    .sensor-block {
      width: calc(50% - 2rem); /* 2 items per row for smaller screens */
      flex: 1 1 calc(50% - 2rem);
    }
  }

  @media (max-width: 700px) {
    .sensor-block {
      width: 100%; /* Full width for single item per row */
      flex: 1 1 100%;
    }
  }

@media (max-width: 750px){
    .Products-container{
        flex-direction: column;
        align-items: center;
    }

    .Products-box{
        width: 60%;
        max-width: 60%;
        margin-bottom: 0;
    }
}