.AgriloProfAssignedUsersBox {
    width: 55rem;
    height: 50rem;
    top: 3rem;
    left: 2.5rem;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    position: relative;

}

.AgriloProfAssignedUsersBox-h1 {
    color: #5A5A5A;
    font-family: "Roboto Mono";
    font-size: 15px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.48px;
    left: 15px;
    top: 5px;
    position: absolute;
}

.AgriloProfAssignedUsersBox-userlist-text {
    color: #B4B4B4;
    font-family: "Roboto Mono";
    font-size: 15px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    left: 45rem;
}

.AgriloProfAssignedUsersBox-labels {
    width: 100%;
    height: 30px;
    position: relative;
    border-bottom: 2px solid #F3F5F6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.AgriloProfAssignedUsersBox-h2 {
    position: relative;
    color: #000;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
}

.AgriloProfAssignedUsersBox-h2.Name {
    right: 12.5rem;
}

.AgriloProfAssignedUsersBox-h2.Email {

}

.AgriloProfAssignedUsersBox-h2.Phone {
    left: 12.5rem;
}



.AgriloProfAssignedUsersBox-ul {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
    left: 0px;
    height: 500px;
    overflow: auto;
}


.users-item {
    position: relative;
    padding: 8px 16px;
    height: 30px;
}

.users-item:hover {
    background: #F3F5F6;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.17);
}


.AgriloProfAssignedUsersBox-users-name {
    width: 15rem;
    font-family: "Roboto Mono";
    color: #000;
    text-align: right;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AgriloProfAssignedUsersBox-users-email {
    width: 15rem;
    color: #616161;
    font-family: "Roboto Mono";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AgriloProfAssignedUsersBox-users-phone {
    width: 15rem;
    color: #616161;
    font-family: "Roboto Mono";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AgriloProfAssignedUsersBox-users-container{
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    top: 0.25rem;
}

.AgriloProfAssignedUsersBox-letter-box {
    width: 100%;
    height: 38px;
    flex-shrink: 0;
    background: #F3F5F6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AgriloProfAssignedUsersBox-h1-letters {
    color: #000;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
}

.AgriloProfAssignedUsersBox-filter-box {
    height: 85px;
    width: 100%;
    border-top: 2px solid #F3F3F7;
}

.AgriloProfAssignedUsersBox-search {
    position: relative;
    top: 20px;
    left: 2.5rem;
    width: 90%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 1.5rem;
    background-color: white;
    /* background-image: url('../ZenroProfIcon/SearchIcon.png'); */
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
}