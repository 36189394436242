.ComposeBox {
    width: 630px;
    height: 730px;
    border-radius: 15px;
    background: #1E1E20;
    position: absolute;
    top: 50px;
}

.ComposeBox-header-text {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.2px;
    position: relative;
    left: 20px;
    top: 5px;
    text-align: left;
}

.ComposeBox-to-box {
    width: 100%;
    height: 46px;
    top: 20px;
    position: relative;
    background: #3C3C40;
    display: flex;
    align-items: center;
}

.ComposeBox-to-text {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    position: relative;
    margin-left: 75px;
}

.ComposeBox-line1 {
    border-right: 1px solid #444446;
    width: 2px;
    height: 45px;
    margin-left: 10px;
    /* Or set a specific height */
    position: relative;
    top: 0px;
    left: 5px;
}

.ComposeBox-editBox {
    color: #D9D9D9;
    position: relative;
    top: 50px;
    max-width: 100%;
    text-align: left;
}

.ComposeBox-add-btn {
    position: relative;
    margin-left: 20px;
    border-radius: 15px;
    font-size: 13px;
    width: 50px;
    height: 30px;
    color: white;
    background-color: #444446;
    box-shadow: none;
    cursor: pointer;
}

.ComposeBox-add-box {
    position: absolute;
    top: 0px;
    left: 200px;
    min-width: 200px;
    width: fit-content;
    background-color: #2e2e30;
    height: fit-content;
    min-height: 30px;
    padding-bottom: 10px;
    z-index: 10; 
}

.ComposeBox-picked-btn {
    position: relative;
    margin-left: 20px;
    border-radius: 15px;
    font-size: 13px;
    color: white;
    background-color: #444446;
    box-shadow: none;
    cursor: pointer;
}

.ComposeBox-picked-btn:hover {
    position: relative;
    margin-left: 20px;
    border-radius: 15px;
    font-size: 13px;
    color: white;
    background-color: red;
    box-shadow: none;
    cursor: pointer;
}

.ComposeBox-subject-box {
    width: 100%;
    height: 46px;
    top: 20px;
    position: relative;
    background: #3C3C40;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.ComposeBox-subject-text {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    position: relative;
    margin-left: 75px;
}

.ComposeBox-subject-input{
    position: relative;
    background: #1e1e2000;
    color: white;
    margin-left: 20px;
}


.ComposeBox-ul {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
    left: 0px;
    height: 200px;
    overflow: auto;
}

.ComposeBox-doctor-item {
    position: relative;
    padding: 8px 16px;
    height: 30px;
}

.ComposeBox-doctor-item:hover {
    background: #3C3C40;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.17);
}

.ComposeBox-test-item.active {
    background-color: #3C3C40;
}

.ComposeBox-doctor-sender {
    color: #FFF;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.ComposeBox-send-button {
    position: relative;
    margin-top: 70px;
    left: -250px;
    height: 40px;
    border-radius: 25px;
    width: 80px;
    font-size: 20px;
    background-color: #505050c4;
    cursor: pointer;
    color: white;
    box-shadow: none;
    border: none;
}



.ComposeBox-send-button:hover {
    background-color: #a5abad;
    color: black;
}

.ComposeBox-compose-btn{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 15px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 10px;
    color: white;
    background-color: #3C3C40;
    box-shadow: none;
    border: none;
}

.ComposeBox-compose-btn:hover{
    background-color: white;
    color: #1E1E20;
}