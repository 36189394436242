@media (min-width: 1024px) {
    .ActivateTokenPage {
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 100%;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ActivateTokenPage-form-box {
        position: absolute;
        width: 500px;
        max-width: 100%;
        max-height: 100%;
        height: 500px;
        max-height: 80%;
        min-height: 20%;
        left: 60%;
        display: flex;
        justify-content: left;
    }

    .ActivateTokenPage-form {
        position: relative;
        top: 100px;
        width: 600px;
        height: 400px;
        right: 180px;
    }


    .ActivateTokenPage-header {
        font-size: 30px;
        top: 25px;
        color: white;
        position: relative;
        flex-shrink: 0;
        left: 50px;
    }

    .ActivateTokenPage-label {
        color: white;
        flex-shrink: 0;
        position: relative;
        width: 500px;
    }

    .ActivateTokenPage-input {
        position: relative;
        top: 20px;
        width: 295px;
        height: 10%;
    }

    .ActivateTokenPage-btn {
        position: relative;
        top: 40px;
        width: 300px;
        height: 40px;
        flex-shrink: 0;
    }

    .ActivateTokenPage-image {
        position: absolute;
        width: 160px;
        height: 30px;
        top: 450px;
        right: 225px;
    }

    .ActivateTokenPage-h2 {
        position: absolute;
        color: white;
        width: 600px;
        max-width: 100%;
        max-height: 100%;
        height: 500px;
        max-height: 80%;
        min-height: 20%;
        top: 90px;
        left: 10%;
    }
}

@media (max-width: 768px) {
    .ActivateTokenPage {
        width: 100%;
        height: 100vh;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
    }


    .ActivateTokenPage-form-box {
        position: absolute;
        width: 300px;
        max-width: 100%;
        height: 500px;
        justify-content: left;

    }

    .ActivateTokenPage-form {
        position: relative;
        width: 600px;
        height: 400px;
    }


    .ActivateTokenPage-header {
        font-size: 30px;
        top: 25px;
        color: white;
        position: relative;
        flex-shrink: 0;
    }

    .ActivateTokenPage-label {
        position: relative;
        display: block;
        width: 300px;
        top: 10px;
        color: white;
    }

    .ActivateTokenPage-input {
        position: relative;
        top: 10px;
        width: 295px;
        top: 20px;
    }

    .ActivateTokenPage-btn {
        position: relative;
        width: 300px;
        height: 40px;
        top: 30px;
    }

    .ActivateTokenPage-image {
        position: absolute;
        width: 160px;
        height: 30px;
        top: 550px;
    }

    .ActivateTokenPage-h2 {
        position: absolute;
        color: white;
    }
}