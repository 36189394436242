.SetUpBox {
    height: 268px;
    width: 585px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #1E1E20;
}

.SetUpBox-h1 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.2px;
    position: absolute;
    top: 7px;
    left: 31px;
}

.SetUpBox-line {
    border-bottom: 2px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    height: 2px; /* Or set a specific height */
    top: 42px;
    position: absolute;
}

.AnalytesSelector {
    position: absolute;
    top: 66px;
    left: 29px;
}

.SensorSelector {
    position: absolute;
    top: 66px;
    left: 373px;
}