/* src/components/Navbar.css */
.TopNavbar-Container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
  z-index: 3;
}

.TopNavbar {
  position: absolute;
    width: 70%;
    top: 3rem;
    height: 3rem;
    min-height: 3rem;
    display: flex;
    contain: content;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    border-radius: 15px;
    border: 0.931px solid rgba(255, 255, 255, 0.40);
    background-color: #4E5E80;
    padding: 0 1rem;
  }
  
  .nav-menu-logo {
    transform-origin: left;
    display: inline-block;
    transform: scaleX(70%) scaleY(70%);
    width: 8rem;
  }

  .nav-options {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    flex: 1;
    max-width: 50%;
    padding: 0;
    margin: 0;
  }
  
  .nav-options a{
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    text-decoration: none;
  }

  .nav-options a:hover{
    font-weight: bold;
  }

  .nav-button {
    border-radius: 10px;
    border: 1px solid #9CA5B6;
    width: 5.5rem;
    height: 2rem;
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.459px;
    cursor: pointer;
  }

  .nav-buttons{
    display: flex;
    gap: 1rem;
  }
  
  .nav-button.login {
    background: rgba(231, 231, 231, 0.36);
    color: #FFF;
  }
  
  .nav-button.signup {
    background: rgba(255, 255, 255, 0.94);
    color: #4A5979;
  }

  .nav-button.user {
    background: rgba(231, 231, 231, 0.36);
    color: #FFF;
  }

  .mobile-nav-menu{
    display: none;
  }

  .mobile-nav-close-button, .mobile-nav-open-button{
    display: none;
  }

  .mobile-nav-menu-logo{
    display: flex;
    position: fixed;
    width: 110vw;
    height: auto;
    scale: .8;
    top: 40%;
    z-index: -1;
  }

@media (max-width: 1200px){
  .TopNavbar{
    min-width: 80%;
  }
}

@media (max-width: 950px){
  .TopNavbar{
    min-width: 90%;
  }
}


@media (max-width: 850px){
  .TopNavbar-Container{
    display: block;
    position: fixed;
  }
  .TopNavbar{
    border-radius: 0;
    top: 0;
    width: 100%;
  }
  .nav-buttons{
    display: none;
  }
  .nav-user{
    display: none;
  }

  .nav-options{
    display: none;
  }

  .mobile-nav-menu{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #10131A;
    left: 100%;
    width: 100%;
    height: 100%;
    padding: 6rem 3.5rem 4.5rem;
    transition: left .4s;
  }

  .mobile-nav-close-button, .mobile-nav-open-button{
    display: flex;
    color: white;
    background-color: transparent;
    border-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .mobile-nav-options{
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    list-style: none;
    justify-content: space-evenly;
    padding: 0;
    margin: 0;
  }

  .mobile-nav-options a {
    position: relative;
    font-size: 2rem;
    color: white;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: opacity .4s;
  }

  .mobile-nav-close-button{
    position: absolute;
    top: 5vh;
    right: 9rem;
    padding: 0;
    margin: 0;
    scale: 1.5;
  }
}

.show-menu{
  left: 0%;
}

.nav-buttons.hidden {
  display: none;
}