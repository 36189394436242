/* src/pages/Home.css */

.home {
    height: auto;
    min-height: 100vh;
    background: linear-gradient(180deg, #4E5E80 0%, #10131A 46.36%);
    color: white;
    font-family: Arial, sans-serif;
}

.home-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 10rem;
    max-width: 90%;
    max-height: 40%;
    margin: auto;
}

.menu-background-logo-container{
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: flex-end;
    pointer-events: none;
}

.menu-background-logo-container img{
    width: 30vw;
    height: auto;
    object-fit: contain;
  }

.Header-text{
    max-width: 45%;
    text-align: left;
}

.main-header1, .main-header2, .main-header-info {
    margin: 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.8;
    margin-bottom: 5%;
}

.main-header1 {
    color: #97ABD8;
  }

  .main-header2 {
    color: #7EA6FF;
  }

  .main-header-info {
    color: #97ABD8;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.72px;
    margin-left: 2%;
}

  .header-video {
    display: inline;
    border-radius: 1rem;
    max-width: 40vw;
    max-height: 40vh;
    z-index: 1;
}

.menu-product-nav-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.menu-product-nav-zenro-button{
    background-color: rgb(87, 116, 179);;
    border-color: white;
    border-radius: .25rem;
    font-weight: bold;
    color: white;
    border-radius: 12px;
    padding: 5px 30px;
    cursor: pointer;
    
}

.menu-product-nav-zenro-button:hover{
    transition: 0.25s;
    scale: 1.1;
    background-color: rgb(38, 51, 80);
}

.menu-product-nav-agrilo-button{
    background-color: rgb(40, 179, 45);
    border-color: white;
    border-radius: .25rem;
    font-weight: bold;
    color: white;
    border-radius: 12px;
    padding: 5px 30px;
    cursor: pointer;
    
}

.menu-product-nav-agrilo-button:hover{
    transition: 0.25s;
    scale: 1.1;
    background-color: rgb(26, 116, 29);
}

@media (min-width: 750px){
    .home-container:nth-of-type(2){
        flex-direction: row-reverse;
    }
}

@media (max-width: 1000px){
    .main-header1, .main-header2{
        font-size: 2.3rem;
    }
    .main-header-info{
        font-size: 1rem;
    }
}

@media (max-width: 750px){
    .home-container{
        flex-direction: column-reverse;
        justify-content: space-between;
        max-width: 100%;
        padding: 3rem 0 0 0;
    }
    .main-header1, .main-header2{
        font-size: 2rem;
    }
    .Header-text{
        max-width: 70%;
        text-align: center;
        font-size: 1rem;
    }

    .header-video{
        width: 100%;
        max-width: 100%;
        max-height: 10%;
        border-radius: 0%;
        padding-bottom: 3rem;
    }

    .menu-background-logo-container{
        justify-content: flex-start;
        margin-top: 6rem;
        transform: rotate(-90deg);
    }

    .menu-background-logo-container img{
        width: 50vw;
        display: none;
      }
}