@media (min-width: 1024px) {
    .ResetPasswordPage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ResetPasswordPage-header {
        font-size: 30px;
        top: 25px;
        color: white;
        position: relative;
        flex-shrink: 0;
        left: 50px;
    }

    .ResetPasswordPage-form-box {
        position: absolute;
        width: 500px;
        max-width: 100%;
        max-height: 100%;
        height: 300px;
        max-height: 80%;
        min-height: 20%;
        left: 60%;

        display: flex;
        justify-content: left;
    }

    .ResetPasswordPage-form {
        position: relative;
        top: 100px;
        width: 600px;
        height: 400px;
        right: 180px;
    }

    .ResetPasswordPage-password {
        position: relative;
        right: 50px;
        bottom: 10px;
        width: 200px;
    }

    .ResetPasswordPage-password-confirm {
        position: relative;
        right: 50px;
        width: 200px;
    }

    .ResetPasswordPage-btn {
        position: relative;
        right: 50px;
        top: 10px;
    }

    .ResetPasswordPage-image {
        position: absolute;
        width: 160px;
        height: 30px;
        right: 210px;
        top: 350px;
    }

    .ResetPasswordPage-h2 {
        position: absolute;
        color: white;
        width: 600px;
        max-width: 100%;
        max-height: 100%;
        height: 500px;
        max-height: 80%;
        min-height: 20%;
        top: 90px;
        left: 10%;
    }

    .ResetPasswordPage-error {
        position: absolute;
        color: red;
        top: 100px;
        left: 325px;
    }

}

@media (max-width: 768px) {
    .ResetPasswordPage {
        width: 100%;
        height: 100vh;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
    }
    

    .ResetPasswordPage-header {
        font-size: 30px;
        top: 25px;
        color: white;
        position: relative;
        flex-shrink: 0;
    }


    .ResetPasswordPage-form {
        position: relative;
        top: 30px;
        width: 300px;
        height: 400px;
    }

    .ResetPasswordPage-password {
        height: 10%;
        position: relative;
        width: 200px;
    }

    .ResetPasswordPage-password-confirm {
        position: relative;
        top: 20px;
        height: 10%;
        width: 200px;
    }

    .ResetPasswordPage-btn {
        position: relative;
        width: 200px;
        top: 30px;

    }

    .ResetPasswordPage-image {
        position: absolute;
        width: 160px;
        height: 30px;

        top: 550px;
    }

    .ResetPasswordPage-h2 {
        position: absolute;
        color: white;
    }

    .ResetPasswordPage-error {
        position: absolute;
        color: red;
    }

}