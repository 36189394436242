.HistoryBox {
    width: 594px;
    height: 750px;
    min-height: fit-content;
    flex-shrink: 0;
    border-radius: 9.562px;
    background: #1E1E20;
    position: absolute;
}

.HistoryBox-h1 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.2px;
    position: relative;
    left: 20px;
    top: 5px;
}

.HistoryBox-line1 {
    border-bottom: 2px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    height: 2px;
    /* Or set a specific height */
    position: relative;
}

/* labelbox */
.HistoryBox-labels {
    width: 100%;
    height: 30px;
    position: relative;
}

.HistoryBox-h2 {
    color: #B3B3B3;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: absolute;
}

.HistoryBox-h2.Status {
    left: 43px;
}

.HistoryBox-h2.Analyte {
    left: 157px;
}

.HistoryBox-h2.Test {
    left: 287px;
}

.HistoryBox-h2.Date {
    left: 449px;
}


.HistoryBox-icon {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 3px solid #B3B3B3;
    flex-shrink: 0;
    transform: scaleX(-1);
    position: absolute;
    top: 8px;
}

.HistoryBox-icon.Status {
    left: 22px;
}

.HistoryBox-icon.Analyte {
    left: 142px;
}

.HistoryBox-icon.Test {
    left: 268px;
}

.HistoryBox-icon.Date {
    left: 426px;
}


/* option box */

.HistoryBox-option-box {
    width: 100%;
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
}

.HistoryBox-filter-btn {
    width: 80px;
    height: 32px;
    border-radius: 23.263px;
    border: none;
    cursor: pointer;
    background-color: #3C3C40;
    position: relative;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;

    display: flex;
    justify-content: center;
    align-items: center;
    left: 15px;
}

.HistoryBox-report-btn {
    width: 147px;
    height: 32px;
    border-radius: 23.263px;
    border: none;
    cursor: pointer;
    background-color: #3C3C40;
    position: relative;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    left: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.HistoryBox-filter-icon {
    position: relative;
    width: 16px;
    height: 15.723px;
    right: 0px;
    margin-right: 5px;
}

/* filter box */

.HistoryBox-filter-box {
    position: relative;
    width: 100%;
    height: 180px;
    border-top: 2px solid rgba(255, 255, 255, 0.17);
    border-bottom: 2px solid rgba(255, 255, 255, 0.17);
    text-align: left;
}

.HistoryBox-filter-daytext {
    width: 200px;
    height: 30px;
    font-size: 18px;
    background-color: #3C3C40;
    color: #B3B3B3;
    border-radius: 10px;
    border: none;
    position: relative;
    top: -10px;
    left: -150px;
}

.HistoryBox-filter-daypicker {
    transform: scale(0.9); /* Scale down to 90% of original size */
    position: relative;
    top: -20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    width: 400px;
    height: 380px;
    background: #3C3C40;
    left: 20px;
    z-index: 100000;
}

.HistoryBox-h5 {
    color: #B3B3B3;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: absolute;
}

.HistoryBox-h5.date {
    position: relative;
    right: 500px;
}

.HistoryBox-h5.severity {
    top: 0px;
    right: 170px;
}

.HistoryBox-severity-selector {
    width: 200px;
    height: 30px;
    font-size: 18px;
    background-color: #3C3C40;
    color: #B3B3B3;
    border-radius: 10px;
    border: none;
    position: relative;
    top: -10px;
    left: 300px;
}

.HistoryBox-search-btn {
    width: 75px;
    height: 32px;
    border-radius: 23.263px;
    border: none;
    cursor: pointer;
    background-color: #3C3C40;
    position: relative;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    top: -12px;
    left: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.HistoryBox-reset-btn {
    width: 75px;
    height: 32px;
    border-radius: 23.263px;
    border: none;
    cursor: pointer;
    background-color: #3C3C40;
    position: relative;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    top: 20px;
    left: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* line */

.HistoryBox-line2 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    height: 1px;
    /* Or set a specific height */
    position: relative;
}

.HistoryBox-ul {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: auto;
}


.HistoryBox-test-item {
    position: relative;
    display: flex;
    padding: 8px 16px;
}

.HistoryBox-test-item:hover {
    background: #3C3C40;
    cursor: pointer
}

.HistoryBox-test-item.active {
    background-color: #3C3C40;
}

.HistoryBox-test-info {
    padding-right: 80px;
    /* Spacing between info items */
}

/* status box */

.HistoryBox-status-box {
    width: 88px;
    height: 24.095px;
    flex-shrink: 0;
    background-color: #2C2C2E;
    border-radius: 12px;
    position: relative;
    display: flex;
}

.HistoryBox-h3 {
    color: #34A851;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: relative;
    left: 10px;
    top: -2px;
    margin-right: 10px;
}

.HistoryBox-h4 {
    color: #34A851;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 7.477px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.299px;
    position: relative;
    top: 2px;
    margin-left: 2px;
}

.HistoryBox-line3 {
    border-right: 1px solid #141414;
    width: 2px;
    height: 20px;
    /* Or set a specific height */
    position: relative;
    top: 2px;
    left: 5px;
}

.HistoryBox-status-icon {
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    border: 3px solid #B3B3B3;
    flex-shrink: 0;
    transform: scaleX(-1);
    position: relative;
    top: 6px;
    left: 4px;
}

.HistoryBox-type-box {
    width: 88px;
    height: 24.095px;
    flex-shrink: 0;
    background-color: #2C2C2E;
    border-radius: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */

    margin-left: 30px;
    color: #B3B3B3;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
}

.HistoryBox-item-name {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: relative;
    width: 200px;
    top: 4px;
    left: 48px;
}

.HistoryBox-item-date {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: relative;
    top: 4px;
    right: 0px;
}