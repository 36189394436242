.AgriloProfEventBox {
    position: relative;
    width: 70rem;
    height: fit-content;
    padding-bottom: 2rem;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    margin-left: 5rem;
    margin-top: 4rem;
}

.AgriloProfEventBox-icon {
    position: relative;
    width: 14.053px;
    height: 20px;
    flex-shrink: 0;
    left: 20px;
    top: 25px;

}

.AgriloProfEventBox-h1 {
    color: #373737;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    position: relative;
    top: -15px;
    left: 50px;
}

.AgriloProfEventBox-line1 {
    position: relative;
    border-bottom: solid 3px #F3F3F7;
    top: -1rem;
}

.AgriloProfEventBox-icon {
    width: 1.5rem;
    height: 1.5rem;
    left: 20px;
    top: 20px;
}

.AgriloProfEventBox-container {
    margin-left: 2rem;
}

.AgriloProfEventBox-label {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.AgriloProfEventBox-input-title {
    width: 60rem;
    height: 3rem;
    border-radius: 10px;
    border: 3px solid #dadadd;
    background: #FFF;
    font-size: 1.75rem;
}

.AgriloProfEventBox-type-add {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    border: 3px solid #c9c9cc;
    border-radius: 10px;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
    margin-bottom: 2rem;
}

.AgriloProfEventBox-textarea-description {
    width: 60rem;
    height: 8rem;
    border-radius: 10px;
    border: 3px solid #dadadd;
    background: #FFF;
    font-size: 1.25rem;
}

.AgriloProfEventBox-reset {
    width: 7rem;
    height: 3rem;
    margin-top: 1rem;
    margin-left: 1rem;
    border-radius: 10px;
    border: 3px solid #dadadd;
    background: #FFF;
    cursor: pointer;
    color: #949494;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-top: 3rem;
}

.AgriloProfEventBox-save {
    width: 7rem;
    height: 3rem;
    border-radius: 10px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    background-color: #4C6CFD;
    margin-top: 3rem;
    margin-left: 1rem;
    cursor: pointer;
}