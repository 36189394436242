.PatientBar {
    width: 330px;
    height: 100%;
    position: absolute;
    right: 0px;
    border-left: solid 2px #F3F3F7;
    top: 0px;
}

.PatientBar-overview-btn {
    position: relative;
    margin-left: 130px;
}

.PatientBar-text {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    left: 30px;
    position: relative;
}


.PatientBar-spotlight {
    border-top: solid 2px #F3F3F7;
    border-bottom: solid 2px #F3F3F7;
    height: 46px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 80px;
}

.PatientBar-medical-history {
    border-top: solid 2px #F3F3F7;
    border-bottom: solid 2px #F3F3F7;
    height: 46px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 20px;
}

.PatientBar-h1 {
    color: #000;
    text-align: left;
    font-family: Poppins;
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    margin-left: 20px;
}

.PatientBar-notes-text-box{
    width: 324px;
    height: 400px;
    right: 0px;
    flex-shrink: 0;
    background-color: #dcdce0;
    color: #000000;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-color: rgba(255, 255, 255, 0.027);
    letter-spacing: 0.4px;
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    resize: none;
    margin-top: 20px;
}

.PatientBar-save-note-button{
    position: relative;
    margin-left: 20px;
    height: 40px;
    width: 80px;
    margin-top: 20px;
    font-size: 20px;
    background-color: #505050c4;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}