.MessagesBox {
    width: 630px;
    height: 730px;
    border-radius: 15px;
    background: #1E1E20;
    position: absolute;
    text-align: left;
    top: 50px;
}

.MessagesBox-h1 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.2px;
    position: relative;
    left: 20px;
    top: 5px;
}

.MessagesBox-top-box {
    margin-top: 20px;
    border-top: 2px solid rgba(255, 255, 255, 0.17);
    height: 70px;
    width: 100%;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.17);
    display: flex;
    justify-content: center;
    align-items: center;
}

.MessagesBox-notification-box {
    width: 42px;
    height: 25px;
    border-radius: 25px;
    margin-right: 300px;
    background: #3C3C40;
    min-width: fit-content;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MessagesBox-notification-text {
    color: #F3F5F6;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.4px;
}

.MessagesBox-options {
    width: 164px;
    height: 31px;
    border-radius: 20px;
    position: relative;
    background: #3C3C40;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MessagesBox-sent-btn {
    width: 80px;
    height: 25px;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    text-decoration: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
}

.MessagesBox-inbox-btn {
    width: 80px;
    height: 25px;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    text-decoration: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
}


.MessagesBox-ul {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
    left: 0px;
    height: 500px;
    overflow: auto;
}


.MessagesBox-email-item {
    position: relative;
    padding: 8px 16px;
    height: 50px;
}

.MessagesBox-email-item:hover {
    background: #3C3C40;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.17);
}

.MessagesBox-test-item.active {
    background-color: #3C3C40;
}


.MessagesBox-email-sender {
    color: #FFF;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.MessagesBox-item-date {
    color: #FFF;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-left: 360px;
}

.MessagesBox-email-text {
    position: absolute;
    color: #D9D9D9;
    top: 40px;
    left: 10px;
    text-align: left;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
}

.MessagesBox-info-box {
    border-top: 2px solid rgba(255, 255, 255, 0.17);
    height: 200px;
    width: 100%;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.17);
}

.MessagesBox-date-box {
    width: 100%;
    height: 35px;
    position: relative;
    background: #3C3C40;
    margin-top: 10px;
}

.MessagesBox-from-box {
    width: 100%;
    height: 35px;
    position: relative;
    background: #3C3C40;
    margin-top: 10px;
}

.MessagesBox-to-box {
    width: 100%;
    height: 35px;
    position: relative;
    background: #3C3C40;
    margin-top: 10px;
}

.MessagesBox-subject-box {
    width: 100%;
    height: 35px;
    position: relative;
    background: #3C3C40;
    margin-top: 10px;
}

.MessagesBox-email-info-text {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    position: relative;
    margin-left: 10px;
    top: 10px;
}

.MessagesBox-back-btn {
    position: relative;
    margin-left: 20px;
    border-radius: 15px;
    font-size: 20px;
    color: white;
    background-color: #444446;
    box-shadow: none;
    cursor: pointer;
    bottom: 25px;
    left: 450px;
}

.MessagesBox-email-content {
    position: relative;
    font-size: 15px;
    color: white;
    margin-left: 10px;
    width: 650px;
    height: 500px;
    overflow: auto;
}

.MessagesBox-compose-btn{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 10px;
    color: white;
    background-color: #3C3C40;
    box-shadow: none;
    border: none;
}

.MessagesBox-compose-btn:hover{
    background-color: white;
    color: #1E1E20;
}