.Zenro {
    height: fit-content; /* Use vh (viewport height) to always be full screen */
    width: 100vw;
    height: 100%;
    min-height: 919px;
    min-width: 1550px;
    background: #100F13;
    top: 0px;
    position: absolute;
}


.ZenroNavagatorBar {
    top: 60px;
    left: 25px;
}

.Zenro-nav{
    width: 100%;
    height: 50px;
    position: absolute;
    top: -30px;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.Zenro-content {
    position: relative;
    width: 10%;
    top: 20px;
}