.Pricing {
    height: auto;
    min-height: 100vh;
    background: linear-gradient(180deg, #4E5E80 0%, #10131A 46.36%);
    color: white;
    font-family: Arial, sans-serif;
}

.pricing-page-container{
    padding-top: 6rem;

    place-items: center; /* Centers the content both vertically and horizontally */
}

/* Main Container */
.pricing-container {
    font-family: 'Arial', sans-serif;
    border-radius: 0.75rem;
    border: 0.715px solid rgba(255, 255, 255, 0.40);
    background: rgba(250, 250, 250, 0.08);
    backdrop-filter: blur(1.537209391593933px);
    padding: 2rem;
    max-width: 90%;
    width: 75%;
    min-height: 18rem;
    height: auto;
    margin-bottom: 3.5rem;
    display: flex;
    flex-direction: column;
}

.Zenro-Title{
    font-size: 2rem;
}

#pricing-linebreak {
    width: calc(100% + 4rem); /* Expands margin of parent container */
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0 -2rem; /* Override parent container margin */
    margin-top: .5rem;
}

/* Header Section */
.pricing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .5rem;
}

    .pricing-header h2 {
        font-size: 1.5em;
        color: #d2d2ff;
        margin: 0;
    }

    .pricing-header {
        font-size: 1em;
        color: #8fa6c8;
        text-decoration: none;
    }

/* Main Content */
.pricing-content {
    display: flex;
    margin-top: 2rem;
    gap: 2rem;
    padding: 0px;
}

.pricing-info {
    flex: 1;
    text-align: left;
    margin-right: 1rem;
}

.pricing-info h1 {
    font-size: 1.8em;
    color: #d2d2ff;
    margin: 0 0 .5rem 0;
}

    .pricing-info span {
        color: #7EA6FF;
    }

.pricing-info p {
    color: #8fa6c8;
}

.pricing-footer {
    display: flex;
    justify-content: space-between; /* Spaces out the elements */
    padding: 1.25rem;
    font-size: 1rem;
}

.question-text {
    margin: 0;
    color: #97ABD8;
}

.pricing-link {
    color: #4E5E80; /* Blue color for the link */
    white-space: nowrap;
}

    .pricing-link:hover {
        font-weight: bold;
        text-decoration: underline;
    }

@media (max-width: 950px){
    .pricing-content{
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 550px){
    .pricing-footer{
        flex-direction: column;
        align-items: center;
        text-align:  center;
    }
}

