.MessagingPage {
    width: 1412px;
    height: 850px;
    min-height: auto;
    background: #100F13;
    display: flex;
    align-items: center; /* Center vertically */
    position: relative;
    left: 130px;
    top: 25px;
  }
  
  .ComposeBox {
    bottom: 100px;
    left: 800px;
  }
  
  .MessagesBox {
    bottom: 100px;
    left: 100px;
  }