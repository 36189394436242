/* src/components/Container.css */
.bottom-nav-container {
    border-top: 2px solid #A1A5AD;
    width: 100%;
    height: auto;
    margin-top: 5rem;
}

.BottomNav {
  padding: 3rem 5rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: start;
}

.small-logo {
    margin: 0;
    padding: 0;
    scale: .7;
}

.BottomNavOptions{
  display: flex;
  justify-content: space-between;
  flex: 1;
  max-width: 50%;
  padding: 0;
  margin: 0;
}

.BottomNavCategory {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.BottomNavCategory a {
  color: #ffffff80;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.56px;
  text-decoration: none;
}

.BottomNavCategory li:first-of-type {
  margin-bottom: 2rem;
}

.BottomNavCategory li:first-of-type a {
    color: #FFF;
}

@media (max-width: 900px){
  .BottomNav-main-options-li a {
    font-size: .75rem;
  }
}

@media (max-width: 750px){
  .BottomNav{
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem;
    width: 100vw;
    height: auto;
  }
  .BottomNav-main-options-li a {
    font-size: 1rem;
  }
  .BottomNavOptions{
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 100%;
    width: 100%;
    margin-top: 1rem;
  }
  .BottomNav-main-options-li a {
    font-size: .75rem;
  }
  .BottomNavCategory li:first-of-type {
    margin-bottom: 1rem;
  }
  .BottomNav-main-options-li:last-of-type {
    margin-bottom: 1rem;
  }
}