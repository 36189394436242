/* src/pages/SignUpPage.css */
.signUp {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background: #100F13;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
  min-height: 100vh;
}

.left-image {
  position: absolute;
  top: 2.5%;
  left: 0px;
  width: 1512px;
  max-width: 20%;
  min-width: 20%;
}

.line {
  position: 'absolute';
  top: 0;
  bottom: 0;
  left: '50%';
  width: '100px';
  background: 'white';
}

.SignUpPage-right-image {
  width: 20%;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.SignUpPage-bottom-right-image {
  position: relative;
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8%;
}

.SignUpPage-welcome-text-signup {
  color: #FFF;
  font-family: "Century Gothic";
  font-size: 25.496px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.35px;
  position: relative;
  text-align: center;
}

.signup-button {
  background: rgba(255, 255, 255, 0.94);
  color: #4A5979;
  border-radius: 10px;
  border: 1px solid #9CA5B6;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  width: 4rem;
  height: 7%;
  margin-right: 10px;
  flex-shrink: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.459px;
  cursor: pointer;
  position: relative;
  margin-top: 8%;
}

.SignUpPage-label {
  color: #9da0a5;
  font-family: "Century Gothic";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.56px;
  position: relative;
  display: block;
  margin-top: 3%;
  margin-left: 15%
}

.SignUpPage-form {
  margin: 10px;
  width: 23%;
  height: 80%;
  position: relative;
  margin-left: 45%;
  min-width: fit-content;
  min-height: fit-content;
}

.SignUpPage-input {
  position: relative;
  padding: 10px;
  margin-top: 20px;
  border-radius: 15px;
  border: 1px solid #9CA5B6;
  background: rgba(231, 231, 231, 0.19);
  font-size: large;
  color: white;
  display: 'inline-flex';
  justify-content: 'flex-end';
  align-items: 'center';
  min-height: 30px;
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.signUp-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signUp-box-email {
  width: 90%;
  display: flex;
  border: 1px solid white;
  background-color: #ffffff3b;
  height: 13%;
  top: 20px;
  position: absolute;
  align-items: center;
  flex-direction: column;
}


.signUp-h2 {
  color: white;
  width: 60%;
  text-align: center;
}



/* new form */

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

/* Mark the active step: */
.step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: #04AA6D;
}

.SignUpPage-loading-text {
  color: #FFF;
  font-family: "Century Gothic";
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.35px;
  position: relative;
  text-align: center;
}

.SignUpPage-button-box {
  display: inline-block;
  width: 4rem;
  position: relative;
  margin-right: 3rem;
  display: flex;
  justify-content: right;
}