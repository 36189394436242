.AgriloTaskBar {
    width: 20rem;
    height: 100%;
    position: absolute;
    right: 0px;
    border-left: solid 2px #F3F3F7;
    top: 0px;
}

.AgriloTaskBar-event-start {
    border-top: solid 2px #F3F3F7;
    border-bottom: solid 2px #F3F3F7;
    height: 46px;
    display: flex;
    align-items: center;
    position: relative;
    top: 100px;
}

.AgriloTaskBar-event-text {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    left: 30px;
    position: relative;
}

.AgriloTaskBar-ul {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    top: 110px;
}


.AgriloTaskBar-test-item {
    position: relative;
    padding-top: 10px;
}

.AgriloTaskBar-eventbox {
    position: relative;
    height: 5rem;
    width: 100%;
    border-bottom: solid 2px #F3F3F7;
}

.AgriloTaskBar-task-color {
    width: 29px;
    height: 8px;
    flex-shrink: 0;
    border-radius: 6.75px;
    position: relative;
    left: 20px;
}

.AgriloTaskBar-task-name {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    bottom: 1.75rem;
    left: 60px;
}

.AgriloTaskBar-task-range {
    color: #666668;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    bottom: 30px;
    left: 60px;
}

.AgriloTaskBar-description {
    position: relative;
    height: fit-content;
    max-height: 150px;
    width: 100%;
    border-bottom: solid 2px #F3F3F7;

}

.AgriloTaskBar-description-header {
    color: #5A5A5A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    left: 20px;
}

.AgriloTaskBar-description-text {
    width: 90%;
    color: #9d9da0;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    left: 20px;
}