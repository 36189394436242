.AgriloOverviewDisplay {
    position: relative;
    margin: 30px;
    width: 40rem;
    height: 25rem;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    
}

.AgriloOverviewDisplay-title {
    position: absolute;
    color: #000;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.441px;
    top: 15px;
    left: 30px;
}

.AgriloOverviewDisplay-date {
    position: absolute;
    color: #D4D4D5;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.27px;
    bottom: 0rem;
    left: 20px;
}

/* right bar */
.AgriloOverviewDisplay-bar {
    position: absolute;
    width: 5rem;
    height: 20rem;
    display: flex;
    /* background-color: wheat; */
    /* Enable flexbox layout */
    flex-direction: column;
    /* Align items vertically */
    align-items: center;
    top: 6.25rem;
    left: 12px;
    gap: 0.2rem;
}

.AgriloOverviewDisplay-bar>div {
    margin: 2px;
    flex-shrink: 0;
}

.AgriloOverviewDisplay-tick {
    width: 3rem;
    height: 0.5rem;
    border-radius: 5.544px;
    background: #89888D;
}

.AgriloOverviewDisplay-tick-prime {
    width: 2rem;
    height: 0.5rem;
    border-radius: 5.544px;
    background: #4C6CFD;
}

.AgriloOverviewDisplay-tick-high {
    width: 3rem;
    height: 1rem;
    border-radius: 7.001px;
    background: #C83A31;
    color: #FFF;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.269px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AgriloOverviewDisplay-tick-low {
    width: 3rem;
    height: 1rem;
    border-radius: 7.001px;
    background: #CFBF05;
    color: #FFF;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.269px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AgriloOverviewDisplay-tick-optimal {
    width: 4rem;
    height: 1rem;
    border-radius: 7.001px;
    background: #4C6CFD;
    color: #FFF;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.269px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* chemical bar chart */

.AgriloOverviewDisplay-chemcials-box {
    position: absolute;
    left: 120px;
    bottom: 16px;
    width: 250px;
    height: 200px;
    /* background-color: wheat; */
    display: flex;
}

.AgriloTestsBox-chemical {
    left: -1.5rem;
    padding-right: 0.5rem;
    position: relative;
    width: fit-content;
    height: 100%;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    align-items: center;

}

.AgriloTestsBox-chemical-name-box{
    width: 8rem;
    margin-top: 11rem;
    display: flex;
    max-width: 20rem;
    flex-direction: column;
    align-items: center;
}
.AgriloTestsBox-chemical-chem-box {
    margin-top: 0.10rem;
    padding-left: 5.5px;
    padding-right: 5.5px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    border-radius: 12.519px;
    border: 2px solid #89888D;
    flex-shrink: 0;
    color: #89888D;
    font-family: Poppins;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.27px;
}
.AgriloTestsBox-chemical-crop-box {
    margin-top: auto;
    padding-left: 5.5px;
    padding-right: 5.5px;
    min-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    border-radius: 12.519px;
    border: 2px solid #89888D;
    flex-shrink: 0;
    color: #89888D;
    font-family: Poppins;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.27px;
    position: relative;
}

.AgriloTestsBox-chemical-bar {
    bottom: 24.5px;
    position: absolute;
    flex-shrink: 0;
    height: 0px;
    width: 0.5rem;
    flex-shrink: 0;
    border-radius: 6.065px;
    background: #89888D;
}

.AgriloTestsBox-chemical-dot {
    position: absolute;
    bottom: 127px;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    background-color: #4C6CFD;
    border-radius: 50%;
}

.AgriloTestsBox-chemical-result {
    position: absolute;
    color: #4C6CFD;
    font-family: "Roboto Mono";
    font-size: 1rem;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.677px;
    margin-bottom: 2rem;
}

.AgriloTestsBox-chemical-metric {
    position: absolute;
    color: #89888D;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.281px;

}