.ZenroNavagatorBar {
    width: 173px;
    height: 472px;
    flex-shrink: 0;
    background: #1E1E20;
    border-radius: 15px;
    position: absolute;
}

.ZenroNavagatorBar-line1 {
    border-bottom: 2px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    height: 2px; /* Or set a specific height */
    top: 42px;
    position: absolute;
}

.ZenroAppIcon {
    width: 23.815px;
    height: 22.124px;
    flex-shrink: 0;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.ZenroNavagatorBar-ul {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
    left: 0px;
    list-style-type: none;
}
.ZenroNavagatorBar-options:hover {
    background: #3C3C40;
    cursor: pointer
}

.ZenroNavagatorBar-options.active {
    background-color: #3C3C40; 
  }


.ZenroNavagatorBar-option1 {
    width: 173px;
    height: 37px;
    flex-shrink: 0;
    margin-top: 20px;
    display: flex;
    align-items: center; /* Center vertically */
}

.OverviewIcon {
    width: 17px;
    height: 17px;
    flex-shrink: 0;
    margin-left: 13px;
}

.ZenroNavagatorBar-h1 {
    color: #FFF;
    font-family: "SF Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.1px;
    font-family: "Roboto Mono";
    margin-left: 13px;
}

.ZenroNavagatorBar-option2 {
    width: 173px;
    height: 37px;
    flex-shrink: 0;
    display: flex;
    align-items: center; /* Center vertically */
}

.HistoryIcon {
    width: 15.328px;
    height: 13.043px;
    flex-shrink: 0;
    margin-left: 13px;
}
.ZenroNavagatorBar-option3 {
    width: 173px;
    height: 37px;
    flex-shrink: 0;
    display: flex;
    align-items: center; /* Center vertically */
}
.RecommendationsIcon {
    width: 15.328px;
    height: 13.043px;
    flex-shrink: 0;
    margin-left: 13px;
}
.ZenroNavagatorBar-option4 {
    width: 173px;
    height: 37px;
    flex-shrink: 0;
    display: flex;
    margin-top: 20px;
    border-top: 2px solid #3C3C40;
    border-bottom: 2px solid #3C3C40;
    align-items: center; /* Center vertically */
}
.MessageIcon {
    width: 15.328px;
    height: 13.043px;
    flex-shrink: 0;
    margin-left: 13px;
    
}

.ZenroNavagatorBar-h2-name {
    width: 155px;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11.5px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.46px;
    position: relative;
    top: 10px;
    
}

.ZenroNavagatorBar-h2-email {
    width: 155px;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 8.625px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.345px;
    position: relative;
    top: 10px;
}

.ZenroNavagatorBar-option5 {
    width: 155px;
    height: 60px;
    flex-shrink: 0;
    margin-top: 150px;
    display: list-item;
    margin-left: 9px;
    align-items: center;
}

.ZenroNavagatorBar-sign-out{
    color: white;
    margin-left: 10px;
    cursor: pointer;
    width: fit-content;
}

.ZenroNavagatorBar-sign-out:hover{
    color: white;
    margin-left: 10px;
    text-decoration: underline;
}