.PatientsBox {
    width: 650px;
    height: 713px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    position: relative;

}

.PatientsBox-h1 {
    color: #5A5A5A;
    font-family: "Roboto Mono";
    font-size: 15px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.48px;
    left: 15px;
    top: 5px;
    position: absolute;
}

.PatientsBox-userlist-text {
    color: #B4B4B4;
    font-family: "Roboto Mono";
    font-size: 15px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    left: 500px;
}

.PatientsBox-labels {
    width: 100%;
    height: 30px;
    position: relative;
    border-bottom: 2px solid #F3F5F6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.PatientsBox-h2 {
    position: relative;
    color: #000;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
}

.PatientsBox-h2.Name {
    right: 70px;
}

.PatientsBox-h2.Email {
    margin-left: 50px;
}

.PatientsBox-h2.Phone {
    margin-left: 120px;
}


.PatientsBox-ul {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
    left: 0px;
    height: 500px;
    overflow: auto;
}


.patient-item {
    position: relative;
    padding: 8px 16px;
    height: 30px;
}

.patient-item:hover {
    background: #F3F5F6;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.17);
}


.PatientsBox-patient-name {
    font-family: "Roboto Mono";
    color: #000;
    text-align: right;
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    left: 80px;
    position: relative;
}

.PatientsBox-patient-email {
    color: #616161;
    font-family: "Roboto Mono";
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    position: absolute;
    left: 260px;
}

.PatientsBox-patient-phone {
    color: #616161;
    font-family: "Roboto Mono";
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
    position: absolute;
    left: 420px;
}

.PatientsBox-letter-box {
    width: 100%;
    height: 38px;
    flex-shrink: 0;
    background: #F3F5F6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PatientsBox-h1-letters {
    color: #000;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 11.025px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.441px;
}

.PatientsBox-filter-box {
    height: 85px;
    width: 100%;
    border-top: 2px solid #F3F3F7;
}

.PatientsBox-search{
    position: relative;
    top: 20px;
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('../ZenroProfIcon/SearchIcon.png');
    background-position: 10px 10px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
}