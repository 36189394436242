.AgriloProfTimeBox {
    position: relative;
    width: 70rem;
    height: fit-content;
    padding-bottom: 2rem;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #F3F3F7;
    background: #FFF;
    margin-left: 5rem;
    margin-top: 5rem;
}

.AgriloProfTimeBox-icon {
    position: relative;
    width: 14.053px;
    height: 20px;
    flex-shrink: 0;
    left: 20px;
    top: 25px;

}

.AgriloProfTimeBox-h1 {
    color: #373737;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    position: relative;
    top: -10px;
    left: 40px;
}

.AgriloProfTimeBox-line1 {
    position: relative;
    border-bottom: solid 3px #F3F3F7;
    top: -1rem;
}

.AgriloProfTimeBox-container {
    margin-left: 2rem;
}

.AgriloProfTimeBox-label {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
}

.AgriloProfTimeBox-date-container {
    display: flex;
    align-items: center;
}

.AgriloProfTimeBox-from {
    width: 10rem;
    height: 2rem;
    border-radius: 10px;
    margin-right: 1rem;
    border: 3px solid #dadadd;
}

.AgriloProfTimeBox-to {
    width: 10rem;
    height: 2rem;
    margin-left: 1rem;
    border-radius: 10px;
    border: 3px solid #dadadd;
}

.AgriloProfTimeBox-small-label {
    color: #2A2A2A;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-top: 0.2rem;
}

.AgriloProfTimeBox-input-frequency {
    position: relative;
    margin-left: -0.25rem;
}

.AgriloProfTimeBox-input-every {
    position: relative;
    margin-left: -0.2rem;
    margin-right: 1rem;
}

.AgriloProfTimeBox-input-one-time {
    position: relative;
    margin-right: 1rem;
}

.AgriloProfTimeBox-every {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
}

.AgriloProfTimeBox-select-num {
    width: 3rem;
    height: 2rem;
    margin-left: 1rem;
    border-radius: 10px;
    margin-right: 1rem;
}

.AgriloProfTimeBox-select-unit {
    width: 6rem;
    height: 2rem;
    border-radius: 10px;
}

.AgriloProfTimeBox-until {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    margin-top: 1rem;
}

.AgriloProfTimeBox-reset {
    width: 7rem;
    height: 3rem;
    margin-left: 1rem;
    border-radius: 10px;
    border: 3px solid #dadadd;
    background: #FFF;
    cursor: pointer;
    color: #949494;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-top: 3rem;
}

.AgriloProfTimeBox-save {
    width: 7rem;
    height: 3rem;
    margin-top: 1rem;
    margin-left: 1rem;
    border-radius: 10px;
    border: 3px solid #4C6CFD;
    background: #4C6CFD;
    color: #FFF;
    cursor: pointer;
}