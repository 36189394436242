.RecentTestCircle {
    width: 275px;
    height: 250px;
    position: absolute;
    margin-top: 70px;
    left: 45px;
    position: absolute;
}

.dial1 {
    width: 57px;
    height: 56px;
    flex-shrink: 0;
    stroke-width: 18.655px;
    stroke: #2C2C2E;
    position: absolute;
    top: 190px;
    left: 30px;
}

.dial1-line {
    width: 36px;
    height: 35px;
    flex-shrink: 0;
    stroke-width: 2.202px;
    stroke: rgba(255, 73, 61, 0.30);
    position: absolute;
    top: 200px;
    left: 39px;
}

.dial1-circle {
    position: absolute;
    top: 230px;
    left: 70px;
    height: 12px;
    width: 12px;
    stroke: #FF493D;
}

/* dial2 */
.dial2 {
    width: 57px;
    height: 56px;
    flex-shrink: 0;
    stroke-width: 18.655px;
    stroke: #2C2C2E;
    position: absolute;
    top: 115px;
    left: 0;

    transform: rotate(45deg);
    transform-origin: center;
}

.dial2-line {
    width: 36px;
    height: 35px;
    flex-shrink: 0;
    stroke-width: 2.202px;
    stroke: rgba(255, 235, 3, 0.30);
    position: absolute;
    transform: rotate(45deg);
    transform-origin: center;
    top: 124px;
    left: 10px;
}

.dial2-circle {
    position: absolute;
    top: 161px;
    left: 12px;
    height: 12px;
    width: 32px;
    transform: rotate(45deg);
    transform-origin: center;
    stroke: #FFEB03;
}

/* dial3-1 */
.dial3-1 {
    width: 57px;
    height: 56px;
    flex-shrink: 0;
    stroke-width: 18.655px;
    stroke: #2C2C2E;
    position: absolute;
    top: 40px;
    left: 30px;

    transform: rotate(90deg);
    transform-origin: center;
}

.dial3-1-line {
    width: 36px;
    height: 35px;
    flex-shrink: 0;
    stroke-width: 2.202px;
    stroke: rgba(52, 168, 81, 0.20);
    position: absolute;
    transform: rotate(90deg);
    transform-origin: center;
    top: 48px;
    left: 42px;
}

.dial3-1-circle {
    position: absolute;
    top: 78px;
    left: 36px;
    height: 12px;
    width: 12px;
    transform: rotate(90deg);
    transform-origin: center;
    stroke: #34A851;

}

/* dial3-2 */
.dial3-2 {
    width: 57px;
    height: 56px;
    flex-shrink: 0;
    stroke-width: 18.655px;
    stroke: #2C2C2E;
    position: absolute;
    top: 10px;
    left: 110px;

    transform: rotate(135deg);
    transform-origin: center;
}

.dial3-2-line {
    width: 36px;
    height: 35px;
    flex-shrink: 0;
    stroke-width: 2.202px;
    stroke: rgba(52, 168, 81, 0.20);
    position: absolute;
    transform: rotate(132deg);
    transform-origin: center;
    top: 19px;
    left: 120px;
}

/* dial3-3 */
.dial3-3 {
    width: 57px;
    height: 56px;
    flex-shrink: 0;
    stroke-width: 18.655px;
    stroke: #2C2C2E;
    position: absolute;
    top: 40px;
    left: 190px;

    transform: rotate(180deg);
    transform-origin: center;

}

.dial3-3-line {
    width: 36px;
    height: 35px;
    flex-shrink: 0;
    stroke-width: 2.202px;
    stroke: rgba(52, 168, 81, 0.20);
    position: absolute;
    transform: rotate(180deg);
    transform-origin: center;
    top: 48px;
    left: 200px;
}

.dial3-3-circle {
    position: absolute;
    top: 78px;
    left: 230px;
    height: 12px;
    width: 12px;
    transform: rotate(90deg);
    transform-origin: center;
    stroke: #34A851;
}

/* dial4 */
.dial4 {
    width: 57px;
    height: 56px;
    flex-shrink: 0;
    stroke-width: 18.655px;
    stroke: #2C2C2E;
    position: absolute;
    top: 115px;
    left: 219px;

    transform: rotate(225deg);
    transform-origin: center;
}

.dial4-line {
    width: 36px;
    height: 35px;
    flex-shrink: 0;
    stroke-width: 2.202px;
    stroke: rgba(255, 235, 3, 0.30);
    position: absolute;
    transform: rotate(225deg);
    transform-origin: center;
    top: 124px;
    left: 231px;
}

.dial4-circle {
    position: absolute;
    top: 161px;
    left: 231px;
    height: 12px;
    width: 32px;
    transform: rotate(45deg);
    transform-origin: center;
    stroke: #FFEB03;
}

/* dial5 */
.dial5 {
    width: 57px;
    height: 56px;
    flex-shrink: 0;
    stroke-width: 18.655px;
    stroke: #2C2C2E;
    position: absolute;
    top: 190px;
    left: 189px;

    /* transform: rotate(270deg); */
    /* transform-origin: center; */
}


.dial5-line {
    width: 36px;
    height: 35px;
    flex-shrink: 0;
    stroke-width: 2.202px;
    stroke: rgba(255, 73, 61, 0.30);
    position: absolute;
    transform: rotate(270deg);
    transform-origin: center;
    top: 200px;
    left: 200px;
}

.dial5-circle {
    position: absolute;
    top: 230px;
    left: 183px;
    height: 12px;
    width: 32px;
    transform: rotate(45deg);
    transform-origin: center;
    stroke: #FF493D;
}

/* inside dial */

.severity-circle {
    position: absolute;
    left: 55px;
    top: 63px;
    transform: rotate(45deg);
    transform-origin: center;
}

.severity-circle-color {
    stop-color: #ffff20;
}

.severity-circle-h1 {
    position: absolute;
    left: 85px;
    top: 115px;
    width: 77.821px;
    height: 24.903px;
    flex-shrink: 0;
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 20.752px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.83px;
}

.severity-circle-highlight {
    width: 60px;
    height: 74px;
    position: absolute;
    /* transform: rotate(0deg) scaleY(-1); -81px 34px; 80px 37px 137deg; -79px 38px 270deg */
    /* transform-origin: -76px 37px; */
    top: 107px;
    left: 219px;
}

.severity-circle-highlight-triangle {
    height:20px; 
    width:11px;
    position: absolute;
    /* transform: rotate(0deg);
    transform-origin: -85px 9px; -92px 9px; -92px 9px 137deg; -90px 10px 270deg */
    top: 135px;
    left: 230px;
}

.severity-circle-highlight-dial{
    position: absolute;
    transform: rotate(0deg);
    transform-origin: -100px 32px; /*-104px 32px; -104px 29px 137deg; -101px 32px 269deg*/
    top: 112px;
    left: 242px;
}

