.SelectHistoryBox {
    width: 594px;
    height: 570px;
    flex-shrink: 0;
    border-radius: 9.562px;
    background: #1E1E20;
    position: absolute;
    height: auto;
    top: 50px;
}


.SelectHistoryBox-info-box {
    width: 100%;
    height: 60px;
    max-width: 100%;
    flex-shrink: 0;
    background: #3C3C40;
    display: flex;
    align-items: center;
}

.SelectHistoryBox-header-text {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 1.4px;
    margin-top: 20px;
    margin-left: 31px;
    margin-bottom: 18px;
}

.SelectHistoryBox-h2 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    width: 29px;
    height: 10px;
    flex-shrink: 0;
    margin-right: 10px
}

.SelectHistoryBox-h3 {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-right: 20px;
    margin-top: 12px;
}

.SelectHistoryBox-h4 {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
}

.SelectHistoryBox-v1 {
    border-left: 2px solid rgba(255, 255, 255, 0.17);
    width: 5px;
    height: 100%;
    /* Or set a specific height */
    left: 451px;
    position: relative;
}

.SelectHistoryBox-rectangle {
    width: 88px;
    height: 25px;
    background-color: #2C2C2E;
    border-radius: 12.5px;
    text-align: center;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    left: 477px;
    position: absolute;
}

.SelectHistoryBox-h2.test {
    margin-left: 24px;
}

.SelectHistoryBox-resaults {
    margin-top: 70px;
    right: 65px;
}

.SelectHistoryBox-status-icon {
    width: 23px;
    height: 23px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 6px solid #B3B3B3;
    transform: scaleX(-1);
    position: relative;
    top: 25px;
    left: 28px;
}

.SelectHistoryBox-result {
    color: #FFEB03;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.867px;
    position: relative;
    text-align: left;
}

.SelectHistoryBox-result-format {
    color: #FFEB03;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 16.201px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.648px;
    position: relative;
    text-align: left;
    margin-top: 25px;
    margin-left: 5px;

}

.SelectHistoryBox-result-box {
    width: 500px;
    height: 50px;
    bottom: 25px;
    position: relative;
    margin-left: 80px;
    display: flex;
}

.SelectHistoryBox-recommendation-box {
    width: 100%;
    height: 60px;
    top: 200px;
    position: absolute;
    max-width: 100%;
    flex-shrink: 0;
    background: #3C3C40;
    display: flex;
    align-items: center;
}

.SelectHistoryBox-recommendation-text-box{
    width: 100%;
    height: 60px;
    margin-top: 80px;
    position: relative;
    max-width: 100%;
    flex-shrink: 0;
    background: #3C3C40;
    display: flex;
    align-items: center;
    min-height: fit-content;
}
.SelectHistoryBox-diagnosis {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    width: 470px;
    position: relative;
    margin-left: 60px;
    top: 0px;
    text-align: left;
}

.SelectHistoryBox-h5 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.1px;
    position: relative;
    left: 22px;
}

.SelectHistoryBox-v2 {
    border-left: 2px solid rgba(255, 255, 255, 0.17);
    width: 5px;
    height: 100%;
    /* Or set a specific height */
    left: 158px;
    position: relative;
}

.SelectHistoryBox-recommendation-point-box{
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: auto;
    overflow: auto;
    max-height: 310px;
}
.SelectHistoryBox-recommendations {
    position: relative;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 145.455% */
    letter-spacing: 1.1px;
    width: 526px;
    text-align: left;
}


.SelectHistoryBox-status-box {
    width: 147px;
    height: 25px;
    background-color: #2C2C2E;
    border-radius: 12.5px;
    text-align: center;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    left: 175px;
    position: absolute;
}

.SelectHistoryBox-h6 {
    color: #1f1f20;
    text-align: right;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
}

.SelectHistoryBox-h7 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    position: relative;
    top: 60px;
    left: 20px;
}

.SelectHistoryBox-notes-text-box {
    width: 491px;
    height: 200px;
    flex-shrink: 0;
    background-color: #3C3C40;
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 5px;
    border-color: rgba(255, 255, 255, 0.027);
    letter-spacing: 0.4px;
    position: relative;
    top: 22px;
    left: 82px;
    resize: none;
}

.SelectHistoryBox-save-note-button {
    position: relative;
    bottom: -60px;
    right: 0px;
    height: 40px;
    width: 80px;
    font-size: 20px;
    background-color: #505050c4;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}



.SelectHistoryBox-save-note-button:hover {
    background-color: #a5abad;
    color: black;
}