.ZenroProf {
    height: fit-content; /* Use vh (viewport height) to always be full screen */
    width: 100vw;
    height: auto;
    min-height: 910px;
    min-width: 1550px;
    background: #F3F2F8;
    padding-bottom: 100px;
    position: absolute;
}


.ZenroProfNavagatorBar {
    top: 120px;
    left: 205px;
}

.zenroProfIconTop {
    position: fixed;
    left: 30px;
    top: 80px;
    width: 100px;
    height: 20px;
    flex-shrink: 0;
    cursor: pointer;
}

.ZenroProf-nav{
    width: 100%;
    height: 100px;
    position: absolute;
    top: -25px;

    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.ZenroProf-background-nav{
    background-color: gray;
    position: absolute;
    width: 1071px;
    min-width: 71%;
    height: 100px;
    top: 43px;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 15px;
}