.AccountEditPage {
    width: 1412px;
    height: 700px;
    min-height: auto;
    background: #100F13;
    display: flex;
    align-items: center;
    /* Center vertically */
    position: relative;
    left: 130px;
    top: 25px;
}

.account-basic-info-box {
    width: 630px;
    height: 300px;
    border-radius: 15px;
    background: #1E1E20;
    position: absolute;
    top: 35px;
    left: 100px;
}

.account-info-box {
    width: 630px;
    height: 300px;
    border-radius: 15px;
    background: #1E1E20;
    position: absolute;
    top: 35px;
    left: 800px;
}

.account-personal-info-box {
    width: 630px;
    height: 300px;
    border-radius: 15px;
    background: #1E1E20;
    position: absolute;
    top: 400px;
    left: 100px;
}

.AccountEditPage-h1 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.4px;
    position: relative;
    top: 7px;
    left: 25px;
}

.AccountEditPage-line1 {
    border-bottom: 2px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    height: 2px;
    /* Or set a specific height */
    top: 51px;
    position: absolute;
}

.AccountEditPage-h2 {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.64px;
    position: relative;
    left: 20px;
}

.AccountEditPage-h3 {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    position: relative;
    left: 20px;

}

.edit-icon {
    cursor: pointer;
    display: block;
    width: 28.815px;
    height: 28.124px;
    flex-shrink: 0;
    position: relative;
    left: 550px;
    top: 50px;

}



.AccountEditPage-h2.email {
    top: 40px;
}

.AccountEditPage-h3.email {
    top: 40px;
}

.AccountEditPage-h2.password {
    top: 40px;
}

.AccountEditPage-h3.password {
    top: 40px;
}

.edit-icon.password {
    top: 90px;
}


.AccountEditPage-popup {
    position: fixed;
    width: 350px;
    height: 200px;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #1E1E20;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    z-index: 1
  }

.AccountEditPage-h4 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.4px;
    position: relative;
    top: -20px;
    left: 10px;
}

  .AccountEditPage-line2 {
    border-bottom: 2px solid rgba(255, 255, 255, 0.17);
    width: 100%;
    height: 2px;
    /* Or set a specific height */
    top: 50px;
    left: 0px;
    position: absolute;
}

.AccountEditPage-save-button {
    height: 25px;
    width: 60px;
    font-size: 12px;
    background-color: #008CBA;
    border: none;
    border-radius: 4px;
    color: white;
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
}

.AccountEditPage-input1 {
    background-color: #2a2a2b;
    color: white;
    border: 2px solid gray;
    border-radius: 4px;
}

.AccountEditPage-input2 {
    background-color: #2a2a2b;
    color: white;
    border: 2px solid gray;
    border-radius: 4px;
}


.AccountEditPage-close-button {
    height: 40px;
    width: 40px;
    font-size: 30px;
    background-color: #008bba00;
    border: none;
    border-radius: 4px;
    color: grey;
    position: absolute;
    top: 5px;
    right: 7px;
    cursor: pointer;
}

.AccountEditPage-close-button:hover {
    background-color: #404344;
}

.AccountEditPage-form{
    position: relative;
    left: 0px;
    bottom: 20px;;
    width: 350px;
}

.account-sign-out-button {
    width: 230px;
    height: 75px;
    border-radius: 15px;
    background: #1E1E20;
    position: absolute;
    top: 750px;
    left: 100px;
    color: white;
    border-color: gray;
    font-size: large;
    cursor: pointer;
}
