.PrivacyPolicyPage {
    height: auto;
    min-height: 100vh;
    background: linear-gradient(180deg, #4E5E80 0%, #10131A 46.36%);
    color: white;
    font-family: Arial, sans-serif;
}
.PrivacyPolicyPage-Container{
    position: relative;
    padding-top: 6rem;
}

.PrivacyPolicyPage-box {
    margin: 0rem auto 10rem; 
    width: 80%;
    max-width: 70%;
    height: 550px;
    min-height: fit-content;
    border-radius: 11.529px;
    border: 0.715px solid rgba(255, 255, 255, 0.40);
    background: rgba(250, 250, 250, 0.08);
    backdrop-filter: blur(1.537209391593933px);
    font-family: 'Poppins', sans-serif;
    padding: 2rem;
}

.PrivacyPolicyPage-links{
    color: lightgreen;
    text-decoration-line: underline;
    font-size: 1.25rem;
}

@media (max-width: 750px){
    .PrivacyPolicyPage-box h2{
        font-size: 1.5rem;
    } 
    .PrivacyPolicyPage-box h3{
        font-size: 1rem;
    } 
}

@media (max-width: 400px){
    .PrivacyPolicyPage-box h2{
        font-size: 1.25rem;
    } 
    .PrivacyPolicyPage-box h3{
        font-size: 0.75rem;
    }
    .PrivacyPolicyPage-links{
        font-size: 0.75rem;
    }
}