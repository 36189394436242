.ActivateAccountPage {
    top: 0;
    left: 0;
    position: absolute;
    background: #100F13;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.ActivateAccountPage-box {
    width: 90%;
    display: flex;
    border: 1px solid white;
    background-color: #ffffff3b;
    height: 13%;
    margin-top: 20px;
    position: relative;

    align-items: center;     
    flex-direction: column;  
}

.ActivateAccountPage-h2 {
    color: white;
}

.ActivateAccountPage-signin {
    text-decoration-line: underline;
    color: white;
}