.RecentTestBox {
    width: 586px;
    height: 507px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #1E1E20;
    position: absolute;
}

.info-box {
    width: 585px;
    height: 60px;
    max-width: 100%;
    flex-shrink: 0;
    background: #3C3C40;
    display: flex;
    align-items: center;
}

.header-text {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 1.4px;
    margin-top: 20px;
    margin-left: 31px;
    margin-bottom: 18px;
}

.recent-test-h2 {
    color: #B3B3B3;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    width: 29px;
    height: 10px;
    flex-shrink: 0;
    margin-right: 10px
}

.recent-test-h3 {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-right: 20px;
    margin-top: 12px;
}

.recent-test-h4 {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
}

.v1 {
    border-left: 2px solid rgba(255, 255, 255, 0.17);
    width: 5px;
    height: 100%; /* Or set a specific height */
    left: 451px;
    position: relative;
}

.rectangle {
    width: 88px;
    height: 25px;
    background-color: #2C2C2E;
    border-radius: 12.5px;
    text-align: center;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    left: 477px;
    position: absolute;
}

.recent-test-h2.test {
    margin-left: 24px;
}

.RecentTestResults {
    margin-top: 70px;
    right: 65px;
}