.RecentTestResults {
    width: 182.481px;
    height: 217.24px;
    flex-shrink: 0;
    border-radius: 13.034px;
    background: #2C2C2E;
    position: absolute;
}

.box-1 {
    width: 585px;
    height: 31px;
    max-width: 100%;
    flex-shrink: 0;
    background: #2C2C2E;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    border-top-left-radius:  13.034px;
    border-top-right-radius:  13.034px;
}

.recent-test-result-h1 {
    color: #7D7D7D;
    font-family: "Roboto Mono";
    font-size: 9.559px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.382px;
    margin-left: auto;
    margin-right: auto;
}

.RecentTestResults-severity-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 3px solid #34A851;
    flex-shrink: 0;
    transform: scaleX(-1);
}

.RecentTestResults-severity-box {
    width: 25.857px;
    height: 17.238px;
    flex-shrink: 0;
    border-radius: 12.216px;
    background: #2C2C2E;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: absolute;
    left: 10px;
    top: 5px;
}

.box-2 {
    width: 585px;
    height: 100px;
    max-width: 100%;
    flex-shrink: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    background: #2C2C2E;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.recent-test-result-h2 {
    height: 47.409px;
    flex-shrink: 0;
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 2rem;
    font-style: normal;
    font-weight: 457;
    line-height: normal;
    letter-spacing: 1.811px;
    margin-top: 10px;
    display: flex;
    text-align: center;
}

.recent-test-result-h3 {
    color: #FFF;
    font-family: "Roboto Mono";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.652px;
    position: relative;
    margin-top: 0px;
    
}
