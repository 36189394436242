.RecommendationPage {
    width: 1412px;
    height: 850px;
    max-height: 100%;
    background: #100F13;
    display: flex;
    align-items: center; /* Center vertically */
    position: relative;
    left: 130px;
    top: 25px;
  }

  .RecommendationHistoryBox {
    left: 100px;
  }

  .RecommendationSelectionBox {
    top: 15px;
    left: 750px;
  }